import PropTypes from 'prop-types';
import React from "react";
import { Card, CardBody, Col } from "reactstrap";

const CardBox = props => {
  return (
    <React.Fragment>
      {props.stats.map((stat, key) => (
        <Col md="4" key={key}>
          <Card className="rounded-4 shadow-lg" style={{maxHeight:110}}>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-lg me-2">
                  <center>
                    <img src={stat.icon} style={{maxWidth:50}} className="img-fluid" />
                  </center>
                </div>
                <div className="flex-grow-1 pt-2">
                  <h4 className='text-muted' style={{fontSize:"0.9rem"}}>{stat.title}</h4>
                  <h6 className="fw-bold text-small">{stat.value}</h6>
                  <a href={stat.link}  style={{fontSize:"0.9rem"}}>learn more</a>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

CardBox.propTypes = {
  coins: PropTypes.array
};

export default CardBox;
