import React, { useEffect, useState } from "react"

//Import Components
import Navbar from "../../../../components/layout/public/menu/menu"

import Footer from "../../../../components/layout/public/footer/footer"

import SearchBox from "./search-box"
import { Helmet } from "react-helmet"

const SearchPage = () => {
  document.title = "Search Shared Cart - Shopping Cart Share"

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="theme-color" content="#4A4A4A" />
        <meta
          name="description"
          content="Search for a shared cart by entering the cart ID here."
        />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Shopping Cart Share, Share Cart, Multi-Retailer Cart, Transfer Cart, Share Amazon Cart, Share Walmart Cart, Social Shopping List, Share Amazon Wishlist, Combine Store Carts"
        />
        <meta
          property="og:title"
          content=" Shopping Cart Share - Search Shared Cart "
        />
        <meta property="og:url" content="https://shoppingcartshare.com" />
        <meta
          property="og:image"
          content="https://shoppingcartshare.com/site.png"
        />
        <meta
          property="og:description"
          content="Search for a shared cart by entering the cart ID here."
        />
        <meta property="og:site_name" content="Shopping Cart Share" />
        <meta property="og:locale" content="en_US" />
        
      </Helmet>

      <Navbar />
      
      <div className="container-fluid pt-5" style={{minHeight:750}} >
      <SearchBox />
      </div>
      
     
      <Footer />
    </React.Fragment>
  )
}

export default SearchPage
