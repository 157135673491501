import React from "react"

import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"
import { Col, Container, Row } from "reactstrap"

const PrivacyPolicyPage = props => {
  document.title = "Terms & Conditions"

  return (
    <React.Fragment>
      <Navbar />
      <section className="pt-5" style={{ backgroundColor: "blue" }} id="terms">
        <div className=" bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-white-50 p-1">
                <h1
                  className="text-white fw-bolder mb-3"
                  style={{ fontSize: "2.1rem" }}
                >
                  Privacy Policy
                </h1>
                <h4
                  className="text-white pt-3 p-1 fw-light"
                  style={{ lineHeight: 1.5, fontSize: "1rem" }}
                >
                  <p>Last updated: 2023-01-01</p>
                  <p>
                    Please read this privacy policy carefully before using this
                    Website.
                  </p>
                </h4>
              </div>
              <div className="row"></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5 pb-5 m-1">
        <Container>
          <Row>
            <Col>
              <h1>Privacy Policy</h1>

              <p>
                This Privacy Policy (“Policy”) explains how we, as the provider
                of Shopping Cart Share services, collect and process information
                from members and users of our website, software applications,
                and other online services. Our products include, but are not
                limited to, browser extensions, Android and iOS applications,
                and websites.
              </p>

              <p>
                It's important to be aware that we may include links to external
                websites (such as retailers). While we strive to provide secure
                and reliable services, we do not own, operate, or control these
                external websites, and they may have their own privacy policies
                that you should review separately.
              </p>

              <p>
                By accessing or using our products or websites, you consent to
                the practices described in this Privacy Policy.
              </p>

              <h2>1. Information We Collect</h2>

              <p>
                1.1. Personal Information: We may collect personal information
                such as your name, email address, and demographic information
                when you voluntarily provide it to us through forms or account
                registration on our website.
              </p>

              <p>
                1.2. Non-Personal Information: We may collect non-personal
                information such as your IP address, browser type, device
                information, and browsing patterns. This information is
                collected automatically through cookies and similar
                technologies.
              </p>

              <h2>2. Use of Information</h2>

              <p>
                2.1. We use the collected information for the following
                purposes:
              </p>

              <ul>
                <li>a. To provide and personalize our services to you.</li>
                <li>b. To process and fulfill your requests and orders.</li>
                <li>
                  c. To communicate with you regarding our website, products,
                  and services.
                </li>
                <li>
                  d. To improve our website, products, and services based on
                  your feedback.
                </li>
                <li>
                  e. To analyze user behavior and preferences to enhance user
                  experience.
                </li>
                <li>
                  f. To enforce our Terms and Conditions and protect the rights
                  and safety of our users.
                </li>
              </ul>

              <h2>3. Data Sharing and Disclosure</h2>

              <p>
                3.1. We may share your personal information with third-party
                service providers who assist us in operating our website and
                delivering services to you. These service providers are
                obligated to maintain the confidentiality and security of your
                information.
              </p>

              <p>
                3.2. We may disclose your information if required by law or in
                response to a valid legal request, such as a court order or
                government investigation.
              </p>

              <p>
                3.3. In the event of a merger, acquisition, or sale of all or a
                portion of our assets, your information may be transferred to
                the acquiring entity.
              </p>

              <h2>4. Cookies and Tracking Technologies</h2>

              <p>
                4.1. We use cookies and similar tracking technologies to collect
                and store certain information about your interactions with our
                website. These technologies help us analyze website traffic,
                remember user preferences, and deliver targeted advertisements.
              </p>

              <p>
                4.2. You can control the use of cookies through your browser
                settings. However, please note that disabling cookies may affect
                the functionality of our website.
              </p>

              <h2>5. Data Security</h2>

              <p>
                5.1. We implement appropriate security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, or destruction. However, no method of data
                transmission over the internet or electronic storage is
                completely secure. Therefore, we cannot guarantee its absolute
                security.
              </p>

              <h2>6. Third-Party Links</h2>

              <p>
                6.1. Our website may contain links to third-party websites that
                are not owned or controlled by us. We are not
                responsible for the privacy practices or content of these
                websites. We encourage you to review the privacy policies of
                those third-party websites before providing any personal
                information.
              </p>

              <h2>7. Children's Privacy</h2>

              <p>
                7.1. Our website is not intended for use by individuals under
                the age of 16. We do not knowingly collect personal information
                from children. If you believe that we have inadvertently
                collected information from a child, please contact us, and we
                will promptly delete it.
              </p>

              <h2>8. Changes to the Privacy Policy</h2>

              <p>
                8.1. We reserve the right to update or modify this Privacy
                Policy at any time. Any changes will be effective immediately
                upon posting the revised Privacy Policy on our website. We
                encourage you to review this Privacy Policy periodically for any
                updates.
              </p>

              <h2>9. Contact Us</h2>

              <p>
                9.1. If you have any questions or concerns about this Privacy
                Policy or our privacy practices, please contact us at info@shoppingcartshare.com.
              </p>

              <p>
                By using our website, you acknowledge that you have read,
                understood, and agreed to this Privacy Policy. If you do not
                agree to this Privacy Policy, please refrain from using our
                website.
              </p>
 
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </React.Fragment>
  )
}

export default PrivacyPolicyPage
