import React from "react"
import { Row, Col } from "reactstrap"

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row className="p-1 pt-0">
        <div className="mb-4">
          <h5 className="text-white" style={{ fontSize: "1.2rem" }}>
            Shopping Cart Share
          </h5>
        </div>

        <p className="mb-2" style={{ fontSize: "0.8rem" }}>
          {new Date().getFullYear()} © Copyright Shopping Cart Share. All Rights
          Reserved
        </p>
        <p style={{ fontSize: "0.8rem" }}>
          Shopping Cart Share may earn an affiliate commission from any
          purchases facilitated through the use of this website. Please see our{" "}
          <a href="/terms" className="text-white">
            terms of service
          </a>{" "}
          for more details.
        </p>
      </Row>
       
    </React.Fragment>
  )
}

export default FooterLink
