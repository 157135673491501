import React from "react"
import { Container, Row, Col, Card, CardBody, CardFooter } from "reactstrap"

//Images
import client1 from "../../../../assets/images/clients/1.png"
import client2 from "../../../../assets/images/clients/2.png"
import client3 from "../../../../assets/images/clients/3.png"
import client4 from "../../../../assets/images/clients/4.png"
import client5 from "../../../../assets/images/clients/5.png"

import chrome from "../../../../assets/images/browsers/chrome_logo.svg"
import firefox from "../../../../assets/images/browsers/firefox_logo.svg"
import edge from "../../../../assets/images/browsers/edge_logo.svg"
import safari from "../../../../assets/images/browsers/safari_logo.svg"

import heroShareLottie from "../../../../assets/images/hero-share.json"

import Lottie from "lottie-react"

import "../../../../../node_modules/swiper/swiper.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper"

const YouTubeEmbed = ({ videoId }) => {
  return (
    <div className="video-container">
      <iframe
        className="w-100 p-0 m-0"
        style={{ minHeight: 300 }}
        src={""}
      ></iframe>
    </div>
  )
}

const getStartedButton = () => {
  // Detect browser agent
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  const isFirefox = typeof InstallTrigger !== "undefined"
  const isEdge = window.navigator.userAgent.indexOf("Edge") > -1
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  let browserLogo = chrome
  let link =
    "https://chrome.google.com/webstore/detail/shopping-cart-share/agppkkbcgmknmnkedglndagmnncdilck?hl=en&authuser=0"
  if (isChrome) {
    browserLogo = chrome
    link =
      "https://chrome.google.com/webstore/detail/shopping-cart-share/agppkkbcgmknmnkedglndagmnncdilck?hl=en&authuser=0"
  } else if (isFirefox) {
    browserLogo = firefox
    link = "https://addons.mozilla.org/en-US/firefox/addon/shopping-cart-share/"
  } else if (isEdge) {
    browserLogo = edge
  } else if (isSafari) {
    browserLogo = safari
  }

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="btn btn-lg w-100 btn-primary "
    >
      <img
        src={browserLogo}
        style={{ maxWidth: 20 }}
        className="m-2 mt-0 mb-0"
      ></img>
      Install for free
    </a>
  )
}

const Section = () => {
  return (
    <React.Fragment>
      <section
        className="section"
        style={{ backgroundColor: "blue" }}
        id="home"
      >
        <div className="bg-overlay bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="text-white-50 p-1">
                <h1
                  className="text-white fw-bolder mb-3"
                  style={{ fontSize: "2.5rem" }}
                >
                  Share and Consolidate Carts Effortlessly Across Retailers!
                </h1>
                <h4
                  className="text-white pt-2 p-1 fw-light"
                  style={{ lineHeight: 2.0, fontSize: "1rem" }}
                >
                  Easily share your carefully curated cart with friends,
                  business partners, and family. All they need to do is one
                  click and they can import your selected items into their own
                  accounts, making the checkout process incredibly easy for
                  everyone.
                  <br></br>
                  <br></br>
                  But that's not all. Say goodbye to the stress of managing
                  multiple shopping carts from various retailers (e.g., Amazon,
                  Walmart, Bestbuy, etc.). our product allows you to
                  consolidate all your shopping carts into one streamlined and
                  easy-to-manage cart. No more switching between websites or
                  apps. Now, you can have all your shopping needs met in one
                  place. Start sharing and simplifying your shopping today.
                </h4>
              </div>
              <div className=" d-flex d-inline-flex gap-2 w-100 pb-5 p-2">
                {getStartedButton()}
                <a href="/#how" className="btn btn-lg w-100 btn-primary ">
                  How it works
                </a>
              </div>
              <div className=" d-flex d-inline-flex gap-2 w-100 pb-5 p-2">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={10}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    678: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="owl-carousel clients-carousel bg-white rounded-4"
                  id="clients-carousel"
                  dir="ltr"
                >
                  <SwiperSlide className="item ">
                    <div className="client-images">
                      <img
                        src={client1}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client2}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client3}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client4}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="item">
                    <div className="client-images">
                      <img
                        src={client5}
                        alt="client-img"
                        className="mx-auto img-fluid d-block"
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
            <Col lg={7} md={7} sm={12} className="ms-lg-auto">
              <Lottie animationData={heroShareLottie} loop={true} style={{maxWidth:650}}/>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Section
