import React, { useState } from "react"
import { Container, Nav } from "reactstrap"
import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"
import SupportHeroSection from "./hero"
import RetailerBox from "./retailer-box"
import Install from "../install/install"
import { Helmet } from "react-helmet"

const Retailers = props => {
  const retailers = [
    {
      title: "Amazon",
      hostname: "/retailer/amazon",
      logo: "amazon.png",
    },
    {
      title: "Staples",
      hostname: "/retailer/staples",
      logo: "staples.png",
    },
    {
      title: "Target",
      hostname: "/retailer/target",
      logo: "target.png",
    },
    {
      title: "Walmart",
      hostname: "/retailer/walmart",
      logo: "walmart.png",
    },
    {
      title: "Bestbuy",
      hostname: "/retailer/bestbuy",
      logo: "bestbuy.png",
    },
  ]

  const retailers_comming_soon = [
    {
      title: "eBay",
      hostname: "/retailer/ebay",
      logo: "ebay.png",
    },
    {
      title: "AliExpress",
      hostname: "/retailer/aliexpress",
      logo: "aliexpress.png",
    },
    {
      title: "Apple Store",
      hostname: "/retailer/apple",
      logo: "apple.png",
    },
    {
      title: "Costco",
      hostname: "/retailer/costco",
      logo: "costco.png",
    },
    {
      title: "Macy's",
      hostname: "/retailer/macys",
      logo: "macys.png",
    },
    {
      title: "Textbooks",
      hostname: "/retailer/textbooks",
      logo: "textbooks.png",
    },
    {
      title: "Wayfair",
      hostname: "/retailer/wayfair",
      logo: "wayfair.png",
    },
    {
      title: "Walgreens",
      hostname: "/retailer/walgreens",
      logo: "walgreens.png",
    },

    {
      title: "Sam's Club",
      hostname: "/retailer/samsclub",
      logo: "samsclub.png",
    },
    {
      title: "H & M",
      hostname: "/retailer/hm",
      logo: "hm.png",
    },
    {
      title: "Home Depot",
      hostname: "/retailer/homedepot",
      logo: "homedepot.png",
    },
    ,
    {
      title: "Nike",
      hostname: "/retailer/nike",
      logo: "nike.png",
    },

    {
      title: "Office Depot",
      hostname: "/retailer/officedepot",
      logo: "officedepot.png",
    },
  ]

  //meta title
  document.title = "Supported retailers"

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="theme-color" content="#4A4A4A" />
        <meta
          name="description"
          content="Support for all your favorite retailers from Amazon to Walmart and more. Build your cart from multiple retailers and share it with anyone."
        />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Shopping Cart Share, browser extension, Share Cart, Multi-Retailer Cart, Transfer Cart, Share Amazon Cart, Share Walmart Cart, Social Shopping List, Share Amazon Wishlist, Combine Store Carts"
        />
        <meta
          property="og:title"
          content="Shopping Cart Share - Supported Retailers "
        />
        <meta
          property="og:url"
          content="https://shoppingcartshare.com/supported"
        />
        <meta
          property="og:image"
          content="https://shoppingcartshare.com/supported.png"
        />
        <meta
          property="og:description"
          content="Support for all your favorite retailers from Amazon to Walmart and more. Build your cart from multiple retailers and share it with anyone."
        />
        <meta property="og:site_name" content="Shopping Cart Share" />
        <meta property="og:locale" content="en_US" />
      </Helmet>

      <Navbar />
      <SupportHeroSection />
      <div className="page-content">
        <Container fluid>
          <RetailerBox retailers={retailers} next={retailers_comming_soon} />
          <div className="pt-2 pb-5"></div>

          <Install />
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default Retailers
