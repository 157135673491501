// Import scss
import "./assets/scss/theme.scss"

import React from "react"
import PropTypes from "prop-types"
import { Routes, Route } from "react-router-dom"
 

 

// Import Firebase Configuration file
/**
 import { initFirebaseBackend } from "./helpers/firebaseHelper"

 const firebaseConfig = {
  appId: "",
  apiKey: "",
  projectId: "",
  authDomain: "",
  databaseURL: "",
  storageBucket: "",
  messagingSenderId: "",
}
init firebase backend
initFirebaseBackend(firebaseConfig)
*/

// Import Routes all
import { publicRoutes } from "./routes/routes"
import PublicLayout from "components/layout/public/publicLayout"

const App = () => {
  return (
    <React.Fragment>
      <div className="container-fluid bg-dark" hidden={true}>
        <div className="row">
          <div className="col-12 pt-2 blink">
            <div className="alert alert-warning m-1" role="alert">
              global alert
            </div>
          </div>
        </div>
      </div>
      <Routes>
        {publicRoutes.map((route, key) => (
          <Route
            path={route.path}
            element={<PublicLayout>{route.component}</PublicLayout>}
            key={key}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
