const taskTemplate = {
  preAuthTasks: [],
  postAuthTasks: [],
  pushProductsTasks: [
    {
      link: "",
      execute: "GET",
      headers: {},
      payload: "",

      session: false,
    },
  ],
  checkCartTasks: [],
  redirectSuccess: "",
  redirectError: "",
}

/***
 * Task State Machine.
 */
class TaskStateMachine {
  constructor(taskData) {
    this.taskData = taskData
    this.currentState = "preAuth"
    this.outputs = {
      preAuthTasks: [],
      postAuthTasks: [],
      pushProductsTasks: [],
      checkCartTasks: [],
      cleanUpTasks: [],
      errors: [],
    }
  }

  sleepSync(ms) {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    return sleep(ms)
  }

  process(callback) {
    while (this.currentState !== "finished") {
      this.sleepSync(this.taskData.cooling || 100)

      console.log("Current State:", this.currentState)
      switch (this.currentState) {
        case "preAuth":
          console.log("preAuthTasks", this.taskData.preAuthTasks)
          this.outputs.preAuthTasks = this.processTask(
            this.taskData.preAuthTasks
          )

          // pass errors to outputs
          this.outputs.errors = this.outputs.errors.concat(
            this.outputs.preAuthTasks.filter(task => {
              return task.status === "error"
            })
          )

          this.currentState = "postAuth"
          break

        case "postAuth":
          console.log("postAuth", this.taskData.postAuthTasks)
          this.outputs.postAuthTasks = this.processTask(
            this.taskData.postAuthTasks
          )

          // pass errors to outputs
          this.outputs.errors = this.outputs.errors.concat(
            this.outputs.postAuthTasks.filter(task => {
              return task.status === "error"
            })
          )
          this.currentState = "pushProducts"
          break

        case "pushProducts":
          console.log("pushProducts", this.taskData.pushProductsTasks)
          this.outputs.pushProductsTasks = this.processTask(
            this.taskData.pushProductsTasks
          )

          // pass errors to outputs
          this.outputs.errors = this.outputs.errors.concat(
            this.outputs.pushProductsTasks.filter(task => {
              return task.status === "error"
            })
          )
          this.currentState = "checkCart"
          break

        case "checkCart":
          console.log("checkCartTasks", this.taskData.checkCartTasks)
          this.outputs.checkCartTasks = this.processTask(
            this.taskData.checkCartTasks
          )

          // pass errors to outputs
          this.outputs.errors = this.outputs.errors.concat(
            this.outputs.checkCartTasks.filter(task => {
              return task.status === "error"
            })
          )
          this.currentState = "cleanUp"
          break

        case "cleanUp":
          console.log("checkCartTasks", this.taskData.cleanUpTasks)
          this.outputs.cleanUpTasks = this.processTask(
            this.taskData.cleanUpTasks
          )

          // pass errors to outputs
          this.outputs.errors = this.outputs.errors.concat(
            this.outputs.cleanUpTasks.filter(task => {
              return task.status === "error"
            })
          )
          this.currentState = "finished"
          break

        default:
          console.error("state:", this.currentState)
          this.currentState = "finished"
      }
    }

    // Redirect if no errors
    if (this.outputs.errors.length === 0) {
      if (this.taskData.redirectSuccess) {
        window.open(this.taskData.redirectSuccess, "_blank")
      }
    } else {
      if (this.taskData.redirectError) {
        window.open(this.taskData.redirectError, "_blank")
      }
    }

    // Return collected outputs
    callback(this.outputs)
  }

  // write fetch sync
  fetchSync = (url, options) => {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then(response => {
          return response
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  processTask(tasks) {
    let taskOutputs = []
    for (const task of tasks) {
      if (task && task.execute) {
        if (["GET", "POST", "PUT"].includes(task.execute)) {
          let fetchOptions = {
            method: task.execute.toUpperCase(),
            headers: task.headers,
            redirect: "follow",
          }

          if (task.session) {
            fetchOptions.credentials = "include"
            fetchOptions.mode = "cors"
          }

          if (task.payload) {
            fetchOptions.body = task.payload
          }

          this.fetchSync(task.link, fetchOptions)
            .then(response => {
              if (response.ok) {
                taskOutputs.push({ status: "success", value: response })
              } else {
                taskOutputs.push({ status: "error", value: response })
              }
            })
            .catch(error => {
              taskOutputs.push({ status: "error", value: response })
            })
        } else if ("OPEN" === task.execute) {
          window.open(task.link, "_blank")
          taskOutputs.push({ status: "success", value: task.link })
        }
      }
    }
    return taskOutputs
  }
}

export { TaskStateMachine, taskTemplate }
