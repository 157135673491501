import React from "react"

import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"
import { Col, Container, Row } from "reactstrap"

const TermsPage = props => {
  document.title = "Terms & Conditions"

  return (
    <React.Fragment>
      <Navbar />
      <section className="pt-5" style={{ backgroundColor: "blue" }} id="terms">
        <div className=" bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-white-50 p-1">
                <h1
                  className="text-white fw-bolder mb-3"
                  style={{ fontSize: "2.1rem" }}
                >
                  Terms & Conditions
                </h1>
                <h4
                  className="text-white pt-3 p-1 fw-light"
                  style={{ lineHeight: 1.5, fontSize: "1rem" }}
                >
                  <p>Last updated: 2023-01-01</p>
                  <p>
                    Please read this Terms and Conditions agreement carefully
                    before using this Website.
                  </p>
                </h4>
              </div>
              <div className="row"></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5 pb-5 m-1">
        <Container>
          <Row>
            <Col>
              <h2>Welcome to Shopping Cart Share!</h2>

              <p>
                These Terms and Conditions outline the rules and regulations for
                the use of our website. By accessing or using our website, you
                agree to comply with these Terms and Conditions. If you disagree
                with any part of these Terms and Conditions, you must not use
                our website.
              </p>

              <h3>1. Intellectual Property</h3>
              <p>
                1.1. The content on this website, including text, graphics,
                logos, images, and software, is the property of Shopping Cart Share
                and protected by intellectual property laws. You may not use,
                reproduce, distribute, or modify any content from this website
                without our prior written consent.
              </p>

              <h3>2. Affiliate Marketing</h3>
              <p>
                2.1. Shopping Cart Share participates in affiliate marketing programs
                offered by websites such as Amazon, Walmart, and other
                third-party affiliate programs.
              </p>
              <p>
                2.2. We may earn a commission from qualifying purchases made
                through the affiliate links provided on our website. These
                commissions help support the operation of our website.
              </p>
              <p>
                2.3. We strive to provide accurate and up-to-date information
                regarding products and services. However, we do not guarantee
                the accuracy, completeness, or reliability of any information
                provided on our website. It is your responsibility to verify the
                details, pricing, and availability of products or services
                before making a purchase.
              </p>

              <h3>3. User Conduct</h3>
              <p>
                3.1. When using our website, you agree to comply with all
                applicable laws and regulations.
              </p>
              <p>
                3.2. You agree not to engage in any of the following activities:
              </p>
              <ul>
                <li>
                  Violating any intellectual property rights of Shopping Cart Share
                  or third parties.
                </li>
                <li>
                  Transmitting any unlawful, harmful, defamatory, obscene, or
                  otherwise objectionable content.
                </li>
                <li>
                  Impersonating any person or entity or misrepresenting your
                  affiliation with any person or entity.
                </li>
                <li>
                  Interfering with or disrupting the functionality of our
                  website or the servers and networks connected to it.
                </li>
                <li>
                  Collecting or storing personal information of other users
                  without their consent.
                </li>
              </ul>

              <h3>4. Limitation of Liability</h3>
              <p>
                4.1. Shopping Cart Share shall not be liable for any direct,
                indirect, incidental, consequential, or punitive damages arising
                out of or in connection with the use of our website or the
                information provided on it.
              </p>
              <p>
                4.2. We do not guarantee the availability, accuracy, or
                reliability of our website or any information provided on it.
                You acknowledge that any reliance on such information is at your
                own risk.
              </p>

              <h3>5. External Links</h3>
              <p>
                5.1. Our website may contain links to external websites that are
                not owned or controlled by Shopping Cart Share. We do not endorse or
                assume any responsibility for the content, privacy policies, or
                practices of these third-party websites.
              </p>

              <h3>6. Modifications</h3>
              <p>
                6.1. We reserve the right to modify or replace these Terms and
                Conditions at any time without prior notice. By continuing to
                use our website after any changes, you agree to be bound by the
                revised Terms and Conditions.
              </p>

               
             
              <p className="pt-5">
                By using our website, you acknowledge that you have read,
                understood, and agreed to these Terms and Conditions. If you do
                not agree to these Terms and Conditions, you must refrain from
                using our website.
                If you have any questions or concerns regarding these Terms and
                Conditions, please contact us at info@shoppingcartshare.com.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </React.Fragment>
  )
}

export default TermsPage
