import "./receive.css"

import React, { useEffect, useRef, useState } from "react"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Container,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  UncontrolledAccordion,
} from "reactstrap"

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"

import Navbar from "../../../../components/layout/public/menu/menu"
import Footer from "../../../../components/layout/public/footer/footer"
import cartEmpty from "../../../../assets/images/multi-cart.png"

import gtag from 'ga-gtag' 

import { useParams } from "react-router-dom"

// import toast
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// pdf maker
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { send } from "react-ga"
import Install from "pages/public/install/install"
import { Radio } from "react-loader-spinner"
import { TaskStateMachine } from "./state"
pdfMake.vfs = pdfFonts.pdfMake.vfs

const getBrowser = () => {
  // Opera 8.0+
  const isOpera =
    (!!window.opr && !!opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf("OPR/") >= 0

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== "undefined"

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && safari.pushNotification)
    )

  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia

  // Chrome 1 - 71
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  // Blink engine detection
  const isBlink = (isChrome || isOpera) && !!window.CSS

  if (isOpera)
    return { key: "opera", obj: window.opera ? window.opera : window.opr }

  if (isFirefox) return { key: "firefox", obj: window.browser }

  if (isSafari) return { key: "safari", obj: window.safari }

  if (isIE)
    return { key: "IE", obj: window.msBrowser || window.MicrosoftBrowser }

  if (isEdge) return { key: "Edge", obj: window.chrome }

  if (isChrome || isBlink) return { key: "chrome", obj: window.chrome }

  // If none of the conditions match
  return { key: "chrome", obj: window.chrome }
}

const getExtensionID = () => {
  const browser = getBrowser()

  switch (browser.key) {
    case "firefox":
      return process.env.REACT_APP_FIREFOX_EXTENSION_ID || ""
    case "Edge":
      return process.env.REACT_APP_EDGE_EXTENSION_ID || ""
    case "opera":
      return process.env.REACT_APP_OPR_EXTENSION_ID || ""
    case "chrome":
      return process.env.REACT_APP_CHROME_EXTENSION_ID || ""
    default:
      return ""
  }
}

const ReceiveCart = () => {
  const currentBrowser = getBrowser().obj
  const currentBrowserKey = getBrowser().key

  const extensionID = getExtensionID()
  const [, updateState] = useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  // get cart key from url path parameter
  const { cartKey } = useParams()
  const [cart, setCart] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState([])

  const sendingCartRef = useRef(false)

  const [sendingCart, setSendingCart] = useState(false)
  const [sendingError, setSendingError] = useState("")
  const [sendingRetailer, setSendingRetailer] = useState("")

  const [searchKey, setSearchKey] = useState("")

  // Tasks
  const [tasks, setTasks] = useState({})

  // Total price
  const [totalPrice, setTotalPrice] = useState([])

  // pin
  const [pin, setPin] = useState("")
  const [pinError, setPinError] = useState("")
  const [showPinModal, setShowPinModal] = useState(false)

  const [showSharingModal, setShowSharingModal] = useState(false)

  const [useCartLoading, setUseCartLoading] = useState(false)

  const registerMessageListener = () => {
    window.addEventListener("message", event => {
      // We only accept messages from ourselves
      if (event.source !== window) return

      // Check the message type and only process those from the webpage that are marked for the extension
      if (event.data.type && event.data.type === "fromExtensionToPage") {
        // Update task queue
        if (event.data.value.error) {
          setSendingCart(false)
          sendingCartRef.current = false

          setSendingError(event.data.value.error)
        } else {
          setTimeout(() => {
            sendingCartRef.current = false
            setSendingCart(false)
            setSendingError("")
          }, 1500)
        }
      }
    })
  }

  const updateTotalCost = retailers => {
    let cost = {}

    Object.entries(retailers || {}).forEach(([key, retailer]) => {
      if (retailer.currency.ccy in cost) {
        cost[retailer.currency.ccy] += parseFloat(retailer.cost)
      } else {
        cost[retailer.currency.ccy] = parseFloat(retailer.cost)
      }
    })
  }

  const showTotalCost = () => {
    let cost = {}

    Object.entries(cart.retailers || {}).forEach(([key, retailer]) => {
      if (retailer.currency.ccy in cost) {
        cost[retailer.currency.ccy] += parseFloat(retailer.cost)
      } else {
        cost[retailer.currency.ccy] = parseFloat(retailer.cost)
      }
    })

    let totalCost = []
    for (let [key, value] of Object.entries(cost)) {
      totalCost.push(
        <div className="row">
          <div className="col-12 d-flex justify-content-start">
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="p-1">
                  {key} {value.toFixed(2)}
                </h4>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return totalCost
  }
  const createPDF = () => {
    let docDefinition = {
      content: [
        {
          text: "Shopping Cart Share",
          style: "header",
          alignment: "center",
          margin: [0, 0, 0, 50],
        },
        {
          text: "Cart: " + cart.title,
          style: "subheader",
          margin: [0, 0, 0, 10],
        },
        {
          text: "Description: " + cart.description,
          style: "subheader",
          margin: [0, 0, 0, 10],
        },
      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
        },
        subheader: {
          fontSize: 10,
          bold: false,
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
        productInfo: {
          bold: false,
          fontSize: 9,
          color: "black",
        },
        retailerHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
    }

    for (let retailerKey in cart.retailers) {
      let retailer = cart.retailers[retailerKey]
      let tableBody = [
        [
          { text: "ID", style: "tableHeader" },
          { text: "Link", style: "tableHeader" },
          { text: "Title", style: "tableHeader" },
          { text: "Price", style: "tableHeader" },
          { text: "Qty", style: "tableHeader" },
        ],
      ]

      for (let product of retailer.products) {
        tableBody.push([
          { text: product.asin, style: "productInfo" },
          { text: product.link, link: product.link, style: "productInfo" },
          { text: product.title, style: "productInfo" },
          {
            text: retailer.currency.symbol + product.price,
            style: "productInfo",
          },
          { text: product.quantity, style: "productInfo" },
        ])
      }

      docDefinition.content.push({
        text: "https://" + retailer.host,

        style: "retailerHeader",
        margin: [0, 25, 0, 5],
      })
      docDefinition.content.push({
        text:
          "Cart information as of " +
          new Date(retailer.timestamp).toLocaleString(),

        style: "productInfo",
        margin: [0, 0, 0, 5],
      })

      docDefinition.content.push({
        table: {
          widths: [70, "*", "*", 50, 30],
          body: tableBody,
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? "#CCCCCC" : null
          },
        },
      })
    }

    pdfMake.createPdf(docDefinition).download("Shopping-Cart-Share.pdf")
  }

  const sendToExtension = task => {
    gtag('event', 'send_cart_to_extensioon', {
      cart: cartKey,
    })
    
    window.postMessage(
      {
        type: "fromPageToExtension",
        task: task,
      },
      "*"
    )

    sendingCartRef.current = true
    setSendingCart(true)
    setSendingError("")
    setSendingRetailer("")

    setInterval(() => {
      if (sendingCartRef.current) {
        sendingCartRef.current = false
        setSendingCart(false)
        setSendingError(
          "Extension has not responded. Please ensure the extension is installed and try again."
        )
      }
    }, 3000)
  }

  // Detect if the extension is installed or not.
  // If it is installed, send the task to the extension
  // If it is not installed, show a message error promting the installation of the extension
  const processExtensionTask = task => {
    sendToExtension(task)
  }

  // process local task
  const processLocalTask = task => {
    gtag('event', 'send_cart_to_local', {
      cart: cartKey,
    })
     // remove the key from task
    if ("key" in task) {
      delete task.key
    }

    // iterate over task
    Object.values(task).forEach(task => {
      new TaskStateMachine(task).process(output => {
        console.log("output", output)
      })
    })
  }

  const processSingleTask = (key, task) => {
    let _task = {
      key: cartKey,
    }
    _task[key] = task

    if (task.caller === "extension") {
      processExtensionTask(_task)
      setSendingRetailer(cart.retailers[key].host)
    }

    if (task.caller === "website") {
      processLocalTask(_task)
      setSendingRetailer(cart.retailers[key].host)
    }
  }

  // register message listener
  useEffect(() => {
    gtag('event', 'view_shared_cart', {
      cart: cartKey,
    })

    registerMessageListener()
  }, [])

  useEffect(() => {
    updateTotalCost(cart.retailers)
  }, [cart])

  // get the retailer logo
  const getRetailerLogo = hostname => {
    return require(`../../../../assets/images/retailers/${
      hostname.split(".")[1]
    }.png`)
  }

  useEffect(() => {
    setLoading(true)
    const cartKey = window.location.pathname.split("/").pop()
    setSearchKey(cartKey)
    const fetchCart = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cart/${cartKey}`
        )
        if (response.ok) {
          const cart = await response.json()
          setCart(cart)
          setError(false)
        } else {
          setError(true)
        }
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchCart()
  }, [cartKey])

  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
        <div
          className="wrapper wrapper-content pt-5"
          style={{ minHeight: 750 }}
          hidden={!loading}
        >
          <div className="row">
            <div className="col-12 d-flex justify-content-center pt-5">
              <div className="spinner-border p-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <h1 className="text-muted text-center">Loading Cart ...</h1>
          </div>
        </div>
        <div
          id="cartcheckout"
          className="wrapper wrapper-content pt-1"
          style={{ minHeight: 750 }}
          hidden={Object.keys(tasks || {}).length === 0}
        >
          <div
            id="cartReview"
            className="wrapper wrapper-content pt-5"
            hidden={Object.keys(tasks || {}).length === 0}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="ibox shadow-lg">
                  <div className="ibox-content">
                    <div className="row text-center">
                      <div className="d-flex justify-content-center pt-2">
                        <label style={{ fontSize: "0.9rem" }}>
                          To send each cart to your retailer account, simply
                          click the 'Send' button located next to the
                          corresponding retailer cart.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="ibox shadow-sm">
                  <div className="ibox-content">
                    <div className="row pb-2">
                      <div className="col-12 col-sm-12">
                        <div
                          className="row pb-2 text-start p-1"
                          hidden={!sendingCart}
                        >
                          <div className="col-12 d-block">
                            <h4 className="pt-2 h3 text-muted">
                              <Radio
                                visible={true}
                                height="45"
                                width="45"
                                ariaLabel="radio-loading"
                                className="text-black-50"
                                wrapperClass="radio-wrapper"
                                colors={["blue", "blue", "blue"]}
                              />{" "}
                              Sending cart items to {sendingRetailer}
                            </h4>
                          </div>
                        </div>

                        <div className="row" hidden={sendingError === ""}>
                          <div className="col-12">
                            <div className="alert alert-danger" role="alert">
                              {sendingError}
                            </div>
                            <Install
                              content="To send to cart to this retailer the extension has to be installed. Install the extension first, then click send."
                              hideRibbon={true}
                            ></Install>
                          </div>
                        </div>
                        <table className="table table-striped pt-5 ">
                          <thead>
                            <tr>
                              <th scope="col fw-bold">
                                <h2
                                  className="text-muted fw-bold"
                                  style={{ fontSize: "1rem" }}
                                >
                                  Shopping Carts
                                </h2>
                              </th>
                              <th scope="col fw-bold">
                                <div className="row">
                                  <div className="col-12 d-flex d-inline-flex justify-content-end">
                                    <button
                                      className="btn  btn-block btn-outline-primary "
                                      style={{ fontSize: 11 }}
                                      onClick={e => {
                                        e.preventDefault()
                                        setTasks({})
                                      }}
                                      disabled={sendingCart}
                                    >
                                      <i className="fa fa-arrow-left"></i>
                                    </button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(tasks || {}).map(([key, value]) => {
                              return (
                                <tr key={key}>
                                  <td className="d-inline-flex w-100 justify-content-start gap-2">
                                    <img
                                      src={getRetailerLogo(key)}
                                      style={{
                                        maxWidth: 60,
                                        maxHeight: 50,
                                      }}
                                      className="p-2 pt-2"
                                    ></img>
                                    <a
                                      href={"https://" + key}
                                      target="_blank"
                                      className="text-muted"
                                      rel="noreferrer"
                                    >
                                      <label
                                        className="pt-2"
                                        style={{ fontSize: 12 }}
                                      >
                                        https://{key}
                                      </label>

                                      <p className="text-muted m-1">
                                        Total Items :{" "}
                                        {cart.retailers[key].total}
                                      </p>
                                      <p className="text-muted m-1">
                                        Total Products Cost :{" "}
                                        {parseFloat(
                                          cart.retailers[key].cost
                                        ).toFixed(2) +
                                          " " +
                                          cart.retailers[key].currency.ccy}
                                      </p>
                                    </a>
                                  </td>
                                  <td>
                                    <div className="row ">
                                      <div className="col-12 d-flex justify-content-end">
                                        <div className="row m-1">
                                          <button
                                            className="btn btn-block btn-primary m-1 "
                                            onClick={e => {
                                              e.preventDefault()

                                              // disable this button
                                              e.target.disabled = true
                                              e.target.innerHTML = "Sending ..."

                                              processSingleTask(key, tasks[key])

                                              // check if the extension is installed

                                              setTimeout(() => {
                                                e.target.disabled = false
                                                e.target.innerHTML = "Send"
                                              }, 3000)
                                            }}
                                          >
                                            Send
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="cartReview"
          className="wrapper wrapper-content pt-5"
          hidden={loading || Object.keys(tasks || {}).length > 0}
        >
          <div className="row">
            <div className="col-md-9">
              <div
                className="ibox shadow-sm"
                hidden={Object.keys(cart || {}).length === 0}
              >
                <div className="ibox-title">
                  <div className="row pb-2">
                    <div className="col-6 d-flex justify-content-start gap-1">
                      <button
                        className="btn  btn-outline-primary"
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href)
                          toast.success("Copied to clipboard")
                        }}
                      >
                        <i className="fa fa-copy"></i>
                      </button>

                      <button
                        className="btn  btn-outline-primary"
                        onClick={() => {
                          createPDF()
                        }}
                      >
                        <i className="fa   fa-download"></i>
                      </button>

                      <button
                        className="btn  btn-outline-primary"
                        onClick={() => {
                          setShowSharingModal(true)
                        }}
                      >
                        <i className="fa fa-share-alt"></i>
                      </button>
                    </div>
                    <div className="col-6 d-flex justify-content-end gap-1">
                      <button
                        className="btn btn-small btn-block btn-outline-primary "
                        onClick={() => {
                          setShowPinModal(true)
                        }}
                        disabled={
                          Object.keys(cart.retailers || {}).length === 0
                        }
                      >
                        <i className="fa fa-shopping"></i> Save Changes
                      </button>
                      <button
                        className="btn btn-small btn-block btn-outline-primary "
                        onClick={() => {
                          // send the cart to the backend and get a checkout key
                          const sendCart = async () => {
                            setUseCartLoading(true)
                            setSendingError("")
                            try {
                              const response = await fetch(
                                `${process.env.REACT_APP_API_URL}/cart/use/${cartKey}`,
                                {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify(cart),
                                }
                              )
                              if (response.ok) {
                                const responseObj = await response.json()
                                setTasks(responseObj)
                                setUseCartLoading(false)
                              } else {
                                setError(true)
                                setUseCartLoading(false)
                              }
                            } catch (error) {
                              setError(true)
                              setUseCartLoading(false)
                            } finally {
                              setLoading(false)
                              setUseCartLoading(false)
                            }
                          }

                          sendCart()
                        }}
                        disabled={
                          Object.keys(cart.retailers || {}).length === 0 ||
                          useCartLoading
                        }
                      >
                        <i className="fa fa-shopping"></i>{" "}
                        {useCartLoading ? "Loading ..." : "Use Cart"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ibox"
                hidden={Object.keys(cart || {}).length === 0}
              >
                <div className="ibox-content ">
                  <table
                    className="table table-striped"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <strong>Cart ID</strong>{" "}
                          <span className="text-muted d-block pt-1">
                            {cartKey}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Title</strong>{" "}
                          <span className="text-muted d-block">
                            {cart.title}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td hidden={cart.description === ""}>
                          <strong>Description</strong>{" "}
                          <span className="text-muted d-block">
                            {cart.description}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Created Date</strong>
                          <span className="text-muted d-block">
                            {new Date(cart.creationDate).toUTCString()}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total Items</strong>{" "}
                          <span className="text-muted d-block">
                            {Object.keys(cart.retailers || {}).reduce(
                              (total, key) => {
                                return total + cart.retailers[key].total
                              },
                              0
                            )}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="ibox"
                hidden={Object.keys(cart.retailers || {}).length === 0}
              >
                <div className="ibox-content gap-1">
                  <div className="row">
                    <div className="col-12 d-flext justify-content-start ">
                      <div className="row p-0 pt-0 pb-0">
                        <div className="col-12"></div>
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className="col-12">
                        <table className="table table-responsive table-striped bg-white border-1 border-dark ">
                          <thead style={{ fontSize: "0.8rem" }}>
                            <tr>
                              <th scope="col fw-bold">Retailer</th>

                              <th scope="col">Date</th>
                              <th scope="col">Items</th>
                              <th scope="col">Cost</th>
                            </tr>
                          </thead>
                          <tbody style={{ fontSize: "0.8rem" }}>
                            {Object.entries(cart.retailers || {}).map(
                              ([key, retailer]) => {
                                return (
                                  <tr key={key}>
                                    <th style={{ fontSize: 10 }} scope="row">
                                      {retailer.host.replace("www.", "")}
                                    </th>

                                    <td style={{ fontSize: 10 }}>
                                      {new Date(
                                        retailer.timestamp
                                      ).toLocaleString()}
                                    </td>

                                    <th style={{ fontSize: 10 }} scope="row">
                                      {retailer.total}
                                    </th>
                                    <td style={{ fontSize: 10 }}>
                                      {retailer.currency.symbol}
                                      {parseFloat(retailer.cost).toFixed(2)}
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ibox"
                hidden={Object.keys(cart.retailers || {}).length === 0}
              >
                <div className="ibox-content p-0 m-0">
                  <UncontrolledAccordion defaultOpen={open} stayOpen>
                    {Object.entries(cart.retailers || {}).map(
                      ([retailerKey, retailer]) => {
                        return (
                          <AccordionItem key={retailerKey}>
                            <AccordionHeader targetId={retailerKey}>
                              <div className="row">
                                <div className="col-auto">
                                  <img
                                    src={getRetailerLogo(retailer.key)}
                                    style={{
                                      maxWidth: 35,
                                      maxHeight: 50,
                                      paddingBottom: 10,
                                    }}
                                    className="m-1"
                                  ></img>
                                  <p className="fw-bold h5 text-uppercase">
                                    {retailer.key.replace("www.", "")}
                                  </p>
                                  <p className="text-muted text-sm">
                                    {retailer.currency.symbol}
                                    {parseFloat(retailer.cost).toFixed(
                                      2
                                    )} for {retailer.total} items
                                  </p>
                                  <p className="text-muted text-sm">
                                    Created on{" "}
                                    {new Date(retailer.timestamp).toUTCString()}
                                  </p>
                                </div>
                              </div>
                            </AccordionHeader>
                            <AccordionBody accordionId={retailerKey}>
                              <div
                                className="row pt-3 p-0 border-dark p-3 m-1"
                                style={{
                                  borderStyle: "dotted",
                                  borderWidth: "0.1rem",
                                }}
                              >
                                <div className="col-8  d-flex justify-content-start gap-1 ">
                                  <span
                                    className="text-muted"
                                    style={{ fontSize: 9 }}
                                  >
                                    This cart contains {retailer.total} items
                                    and cost {retailer.currency.symbol}
                                    {parseFloat(retailer.cost).toFixed(2)}.
                                    Product prices are as of{" "}
                                    {new Date(retailer.timestamp).toUTCString()}
                                  </span>
                                </div>
                                <div className="col-4  d-flex justify-content-end p-3 pb-0 pt-0">
                                  <a
                                    href="#"
                                    className="error"
                                    onClick={e => {
                                      e.preventDefault()
                                      // remove retailer from cart locally
                                      const newCart = { ...cart }
                                      delete newCart.retailers[retailerKey]
                                      setCart(newCart)
                                    }}
                                  >
                                    Delete Retailer
                                  </a>
                                </div>
                              </div>
                              <hr></hr>

                              {Object.entries(retailer.products || {}).map(
                                ([productKey, product]) => {
                                  return (
                                    <div
                                      className="ibox-content border"
                                      key={productKey}
                                    >
                                      <div className="row">
                                        <div
                                          className="col-3 col-md-3  col-lg-3 d-flex justify-content-center"
                                          style={{ minWidth: 135 }}
                                        >
                                          <a
                                            href="#"
                                            onClick={e => {
                                              e.preventDefault()

                                              window.open(product.link)
                                            }}
                                          >
                                            <img
                                              src={product.image.basic}
                                              className="pt-2 pb-2 "
                                              style={{
                                                maxWidth: 135,
                                                maxHeight: 135,
                                              }}
                                              alt="Product Image"
                                            />
                                          </a>
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-6 d-flex justify-content-start pt-3">
                                          <div className="row p-1">
                                            {product.title}
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-12 col-sm-12 pt-2 d-flex justify-content-end p-4 pb-0 ">
                                          <div className="row ">
                                            <div className="col-6 col-lg-6 p-1">
                                              <div
                                                className="form-group"
                                                style={{ minHeight: 75 }}
                                              >
                                                <label>Qunatity</label>
                                                <input
                                                  style={{
                                                    maxWidth: "70px",

                                                    minHeight: "45px",
                                                    fontSize: "0.9rem",
                                                    textAlign: "center",
                                                  }}
                                                  type="number"
                                                  className="pt-3 form-control input-lg "
                                                  placeholder="Qty"
                                                  min="1"
                                                  step="1"
                                                  onFocus={event => {
                                                    event.target.oldValue =
                                                      event.target.value
                                                  }}
                                                  onChange={event => {
                                                    if (
                                                      event.target.value > 0
                                                    ) {
                                                      const newCart = {
                                                        ...cart,
                                                      }
                                                      newCart.retailers[
                                                        retailer.key
                                                      ].products[
                                                        productKey
                                                      ].quantity =
                                                        event.target.value

                                                      // update retailer total cost after changing this product quantity
                                                      let oldValue =
                                                        event.target.oldValue
                                                      let newValue =
                                                        event.target.value
                                                      if (oldValue < newValue) {
                                                        let existingCost =
                                                          newCart.retailers[
                                                            retailer.key
                                                          ].cost

                                                        newCart.retailers[
                                                          retailer.key
                                                        ].cost =
                                                          existingCost +
                                                          product.price *
                                                            (newValue -
                                                              oldValue)

                                                        // update the total items
                                                        let existingTotal =
                                                          newCart.retailers[
                                                            retailer.key
                                                          ].total

                                                        newCart.retailers[
                                                          retailer.key
                                                        ].total =
                                                          existingTotal + 1
                                                      } else if (
                                                        oldValue > newValue
                                                      ) {
                                                        let existingCost =
                                                          newCart.retailers[
                                                            retailer.key
                                                          ].cost

                                                        newCart.retailers[
                                                          retailer.key
                                                        ].cost =
                                                          existingCost -
                                                          product.price *
                                                            (oldValue -
                                                              newValue)

                                                        // update the total items
                                                        let existingTotal =
                                                          newCart.retailers[
                                                            retailer.key
                                                          ].total

                                                        newCart.retailers[
                                                          retailer.key
                                                        ].total =
                                                          existingTotal - 1
                                                      }

                                                      setCart(newCart)
                                                      updateTotalCost(
                                                        newCart.retailers
                                                      )
                                                      event.target.oldValue =
                                                        event.target.value
                                                    }
                                                  }}
                                                  value={product.quantity}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-6 col-lg-6 text-center p-1">
                                              <div
                                                className="form-group"
                                                style={{ minHeight: 75 }}
                                              >
                                                <label> Price</label>
                                                <div className=" pt-2 m-0">
                                                  <div className="m-1">
                                                    <h5
                                                      className="font-bold pt-2"
                                                      style={{
                                                        fontSize: "0.8rem",
                                                      }}
                                                    >
                                                      {retailer.currency.symbol}
                                                      {parseFloat(
                                                        product.price *
                                                          product.quantity
                                                      ).toFixed(2)}
                                                    </h5>
                                                    <h6
                                                      className="font-bold text-muted"
                                                      style={{
                                                        fontSize: "0.7rem",
                                                      }}
                                                    >
                                                      {product.price}/ea
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row pt-5 p-2 pb-0">
                                        <div className="col-12 d-flex justify-content-end gap-3">
                                          <a
                                            href="#"
                                            onClick={e => {
                                              e.preventDefault()

                                              navigator.clipboard.writeText(
                                                product.link
                                              )
                                              toast.success(
                                                "Copied to clipboard"
                                              )
                                            }}
                                          >
                                            <i className="fa fa-copy"></i> copy
                                            link
                                          </a>

                                          <a
                                            href="#"
                                            className="error"
                                            onClick={e => {
                                              e.preventDefault()
                                              // remove product form cart locally
                                              const newCart = { ...cart }

                                              const productPrice = parseFloat(
                                                newCart.retailers[retailer.key]
                                                  .products[productKey].price *
                                                  newCart.retailers[
                                                    retailer.key
                                                  ].products[productKey]
                                                    .quantity
                                              )

                                              // Delete the product
                                              delete newCart.retailers[
                                                retailer.key
                                              ].products[productKey]

                                              // Delete the retailer if it has no products
                                              if (
                                                Object.keys(
                                                  newCart.retailers[
                                                    retailer.key
                                                  ].products
                                                ).length === 0
                                              ) {
                                                delete newCart.retailers[
                                                  retailer.key
                                                ]
                                              } else {
                                                // update retailer total cost after removing this product
                                                let existingCost =
                                                  newCart.retailers[
                                                    retailer.key
                                                  ].cost

                                                newCart.retailers[
                                                  retailer.key
                                                ].cost =
                                                  existingCost - productPrice

                                                // update retailer total items after removing this product
                                                let existingTotal =
                                                  newCart.retailers[
                                                    retailer.key
                                                  ].total

                                                newCart.retailers[
                                                  retailer.key
                                                ].total = existingTotal - 1
                                              }

                                              setCart(newCart)
                                              updateTotalCost(newCart.retailers)
                                            }}
                                          >
                                            <i className="fa fa-trash error"></i>{" "}
                                            remove
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              )}
                            </AccordionBody>
                          </AccordionItem>
                        )
                      }
                    )}
                  </UncontrolledAccordion>
                </div>
              </div>
            </div>
            <div
              className="col-md-3"
              hidden={Object.keys(cart || {}).length === 0}
            >
              <div className="ibox">
                <div className="ibox-title">
                  <h3>Total Cost</h3>
                  <div
                    className="text-muted text-sm"
                    hidden={Object.keys(cart.retailers || {}).length === 0}
                  >
                    <p style={{ fontSize: "0.7rem" }}>
                      Total cost doesn't include potential taxes and delivery
                      fees. Products prices are as per the time the retailer
                      cart was created and may have changed since.
                    </p>
                    <p
                      className="text-muted text-sm"
                      style={{ fontSize: "0.7rem" }}
                    >
                      Shopping Cart Share may earn an affiliate commission from
                      any purchases facilitated through the use of this website.
                    </p>
                    {showTotalCost()}
                  </div>
                </div>

                <div className="ibox-content">
                  <div className="row d-lg-none ">
                    <div className="col-12 d-flex justify-content-end gap-1 pt-5 d-lg-none ">
                      <button
                        className="btn btn-lg btn-block btn-outline-primary w-100  d-lg-none "
                        onClick={e => {
                          e.preventDefault()
                          // send the cart to the backend and get a checkout key
                          const sendCart = async () => {
                            try {
                              const response = await fetch(
                                `${process.env.REACT_APP_API_URL}/cart/use/${cartKey}`,
                                {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify(cart),
                                }
                              )
                              if (response.ok) {
                                const responseObj = await response.json()
                                setTasks(responseObj)
                              } else {
                                setError(true)
                              }
                            } catch (error) {
                              setError(true)
                            } finally {
                              setLoading(false)
                            }
                          }

                          sendCart()
                        }}
                      >
                        <i className="fa fa-shopping"></i> Use Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="ibox">
              <div
                className="ibox-content gap-1"
                hidden={Object.keys(cart.retailers || {}).length > 0}
              >
                <div className="row">
                  <div className="col-12 text-center pb-5">
                    <div
                      className="input-group input-group-lg"
                      style={{ borderRadius: 7, minWidth: 350 }}
                    >
                      <input
                        type="text"
                        value={searchKey}
                        onChange={e => {
                          e.preventDefault()
                          setSearchKey(e.target.value)
                        }}
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            e.preventDefault()
                            if (searchKey === "") return
                            window.location.href = `/cart/receive/${searchKey}`
                          }
                        }}
                        className="form-control bg-light text-dark border-light"
                        style={{
                          minHeight: 50,
                          fontSize: 14,
                        }}
                        placeholder="Search for a cart"
                      />
                      <div className="input-group-append">
                        <button
                          style={{
                            minHeight: 50,
                            fontSize: 14,
                          }}
                          className="btn btn-lg btn-outline-secondary"
                          type="button"
                          onClick={() => {
                            if (searchKey !== "") {
                              window.location.href = `/cart/receive/${searchKey}`
                            }
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <img src={cartEmpty} style={{ maxWidth: 350 }} />
                    <h1 className="pt-5">Cart Not Found</h1>
                    <p className="pt-1">
                      Check you have the right cart ID and try again
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />

      <Modal isOpen={showPinModal}>
        <ModalHeader>
          <h3>Save Cart Changes</h3>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div
                className="alert alert-danger"
                role="alert"
                hidden={!pinError}
              >
                Incorrect PIN. Enter the correct PIN to save changes to this
                cart.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label className="form-label p-1">
                This cart is PIN protected. To persist your changes enter the
                PIN. Don't have the PIN? No worries! You're still free to adjust
                the cart's contents and utilize the updated version. Please
                remember, in this case, your adjustments won't be saved for
                future use
              </label>
              <div className="form-group mt-2">
                <input
                  type="password"
                  className="form-control input-group input-group-lg "
                  placeholder="Enter the pin"
                  value={pin}
                  onChange={e => {
                    e.preventDefault()
                    setPin(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={e => {
              e.preventDefault()
              setShowPinModal(false)
              setPin("")
              setPinError(false)
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={e => {
              e.preventDefault()
              // send the cart to the backend and get a checkout key
              const sendCart = async () => {
                try {
                  // set pin in cart
                  cart.pin = pin

                  // send request
                  const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/cart/${cartKey}`,
                    {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(cart),
                    }
                  )
                  if (response.ok) {
                    const responseObj = await response.json()
                    setPin("")
                    setShowPinModal(false)
                    toast.success("Cart changes saved successfully")
                  } else {
                    setPinError(true)
                  }
                } catch (error) {
                  setError(true)
                } finally {
                  setLoading(false)
                }
              }

              sendCart()
            }}
          >
            Save Changes
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showSharingModal}>
        <ModalHeader>
          <h3>Share Link</h3>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 d-flex d-inline-flex justify-content-center gap-1">
              <EmailShareButton
                url={document.location}
                subject={"Shopping Cart Share"}
                body="body"
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <FacebookShareButton
                url={document.location}
                quote={"Shopping Cart Share"}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <FacebookMessengerShareButton
                url={document.location}
                appId="521270401588372"
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>

              <TwitterShareButton
                url={document.location}
                quote={"Shopping Cart Share"}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <TelegramShareButton
                url={document.location}
                title={"Shopping Cart Share"}
                className="Demo__some-network__share-button"
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <WhatsappShareButton
                url={document.location}
                title={"Shopping Cart Share"}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={document.location}
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <PinterestShareButton
                url={document.location}
                media={""}
                className="Demo__some-network__share-button"
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              <RedditShareButton
                url={document.location}
                title={"Shopping Cart Share"}
                windowWidth={660}
                windowHeight={460}
                className="Demo__some-network__share-button"
              >
                <RedditIcon size={32} round />
              </RedditShareButton>
              <TumblrShareButton
                url={document.location}
                title={"Shopping Cart Share"}
                className="Demo__some-network__share-button"
              >
                <TumblrIcon size={32} round />
              </TumblrShareButton>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={e => {
              e.preventDefault()
              setShowSharingModal(false)
            }}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ReceiveCart
