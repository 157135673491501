import React, { useState } from "react"
import { Col, Container, Nav, Row } from "reactstrap"
import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"
import { Helmet } from "react-helmet"

const UninstallPage = props => {
  document.title = "We're Sorry to See You Go"

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="theme-color" content="#4A4A4A" />
        <meta
          name="description"
          content="Support for all your favorite retailers from Amazon to Walmart and more. Build your cart from multiple retailers and share it with anyone."
        />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Shopping Cart Share, browser extension, Share Cart, Multi-Retailer Cart, Transfer Cart, Share Amazon Cart, Share Walmart Cart, Social Shopping List, Share Amazon Wishlist, Combine Store Carts"
        />
        <meta
          property="og:title"
          content="We're Sorry to See You Go - Shopping Cart Share"
        />

        <meta property="og:site_name" content="Shopping Cart Share" />
        <meta property="og:locale" content="en_US" />
      </Helmet>

      <Navbar />
      
      <section className="pt-5">
        <Container fluid>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSe_wa8ZraspGpl2tktD3-pUgXMABI7rZkTVS17fIp9hRvS0VA/viewform?usp=sf_link"
            style={{
              minWidth: "100%",
              minHeight: 950,
              padding: 0,
              margin: 0,
              border: 0,
            }}
          >
            Loading…
          </iframe>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  )
}

export default UninstallPage
