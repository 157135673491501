import React from "react"

import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"

import createCartFeature from "../../../assets/images/features/create-frame.png"
import shareCartFeature from "../../../assets/images/features/share-cart.png"

import { Card, Col, Container, Row } from "reactstrap"
import Install from "../install/install"
import InstallRetailer from "../install/installRetailer"
import { Helmet } from "react-helmet"

const BrowserPluginsPage = props => {
  document.title = "Install our free browser plugin, and start sharing now"

  const retailers = [
    {
      title: "Amazon",
      hostname: "https://www.amazon.com",
      logo: "amazon.png",
    },
    {
      title: "Staples",
      hostname: "https://www.staples.com",
      logo: "staples.png",
    },
    {
      title: "Target",
      hostname: "https://www.target.com",
      logo: "target.png",
    },
    {
      title: "Walmart",
      hostname: "https://www.walmart.com",
      logo: "walmart.png",
    },
    {
      title: "Bestbuy",
      hostname: "https://www.bestbuy.com",
      logo: "bestbuy.png",
    },
  ]

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="theme-color" content="#4A4A4A" />
        <meta
          name="description"
          content=" Install our free multi retailer browser plugin and start sharing now"
        />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Shopping Cart Share, browser extension, Share Cart, Multi-Retailer Cart, Transfer Cart, Share Amazon Cart, Share Walmart Cart, Social Shopping List, Share Amazon Wishlist, Combine Store Carts"
        />
        <meta
          property="og:title"
          content="Shopping Cart Share - Install Browser extensions"
        />
        <meta property="og:url" content="https://shoppingcartshare.com/plugins" />
        <meta
          property="og:image"
          content="https://shoppingcartshare.com/extension.png"
        />
        <meta
          property="og:description"
          content="Install our free multi retailer browser plugin and start sharing now"
        />
        <meta property="og:site_name" content="Shopping Cart Share" />
        <meta property="og:locale" content="en_US" />
      </Helmet>
      <Navbar />
      <section className="pt-5" style={{ backgroundColor: "blue" }} id="terms">
        <div className=" bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="text-white-50 p-1">
                <h1
                  className="text-white fw-bolder mb-3"
                  style={{ fontSize: "2.0rem" }}
                >
                  Revolutionizing Online Shopping with Our Free Browser Plugins
                </h1>
                <h4
                  className="text-white pt-3 p-1 fw-light pb-5"
                  style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                >
                  Our Shopping Cart Share family of browser plugins streamline
                  the process of creating, managing, and sharing shopping carts
                  across multiple retailers. With the simple installation of any
                  plugin in our suite, you gain the ability to create multi
                  retailer cart and share cart contents directly from your
                  browser with ease and speed. No registration needed and it's
                  completely free!
                </h4>
              </div>
            </Col>
            <Col lg={6}>
              <Install
                message="it's free"
                content="Ready to transform your shopping experience? Get Shopping cart share now"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5 pb-5 m-1">
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <Card className="p-3 shadow-lg rounded-3 mt-3">
                <h4 className="mb-3 fw-bold">Key Features:</h4>
                <p style={{ fontSize: "0.9rem" }}>
                  Simplify and enhance your online shopping with us today.
                </p>
                <ul style={{ fontSize: "0.9rem" }}>
                  <li>Manage items from multiple retailers in one place.</li>
                  <li>
                    Effortlessly share your favorite retailers shopping carts.
                  </li>
                  <li>Recommend products to friends.</li>
                  <li>
                    Create a report of multi retailer cart with one click.
                  </li>
                  <li>No registration needed and it's completely free!</li>
                </ul>
              </Card>
              <Card className="p-3 shadow-lg mt-3 rounded-3">
                <h4 className="mb-3 fw-bold">Sharing:</h4>
                <p style={{ fontSize: "0.9rem" }}>
                  Whether you're coordinating a group purchase or recommending
                  products to a friend, you can effortlessly share your entire
                  cart, eliminating the need for multiple links or detailed item
                  lists.
                </p>
              </Card>
            </Col>
            <Col xs={12} lg={6}>
              <img
                src={createCartFeature}
                alt="Create Cart Feature"
                className="img-fluid mx-auto d-block rounded-4 pt-2 shadow-lg"
              />
            </Col>
            <Row className="pt-5">
              <Col xs={12} lg={12} className="p-3">
                <h1> Multi retailer plugin</h1>
                <p style={{ fontSize: "0.9rem" }}>
                  This browser plugin supports <b>all our supported retailer</b>
                  , create multi store carts, manage, print, and share with
                  ease.
                </p>
                <Install
                  hideMessage={true}
                  hideRibbon={false}
                  message="Recommended"
                ></Install>
              </Col>
            </Row>
            <Col xs={12} lg={12} hidden={true}>
              <Row className="pt-5">
                <Col xs={12} lg={12} className="p-3">
                  <h1> A Retailer plugin</h1>
                  <p style={{ fontSize: "0.9rem" }}>
                    The browser plugin will support only selected retailer.
                    Multicart feature will not be supported, but you can still
                    share your cart.
                  </p>
                </Col>
                <Col
                  className="d-inline d-block d-flex p-3"
                  style={{ minHeight: 200 }}
                >
                  <div className="d-flex flex-wrap justify-content-center gap-4 rounded-3 pt-5  w-100 bg-white shadow-lg mb-1">
                    {retailers.map((retailer, key) => {
                      return (
                        <InstallRetailer
                          key={key}
                          retailer={retailer}
                        ></InstallRetailer>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </React.Fragment>
  )
}

export default BrowserPluginsPage
