import React, { useEffect, useState } from "react"

//Import Components
import Navbar from "../../../../components/layout/public/menu/menu"

import Footer from "../../../../components/layout/public/footer/footer"

const CreateCart = () => {
  document.title = "Create Shared Cart - Shopping Cart Share"

  return (
    <React.Fragment>
      <Navbar />
      <div className="container-fluid pt-5" style={{ minHeight: 750 }}>
        <div className="row">
          <div className="col-md-12">
             
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  )
}

export default CreateCart
