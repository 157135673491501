import React, { useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

//Images
import chrome from "../../../assets/images/browsers/chrome_logo.svg"
import firefox from "../../../assets/images/browsers/firefox_logo.svg"
import edge from "../../../assets/images/browsers/edge_logo.svg"
import safari from "../../../assets/images/browsers/safari_logo.svg"

import gtag from "ga-gtag"


const Install = props => {
  const retailers = [
    {
      title: "Amazon",
      hostname: "https://www.amazon.com",
    },
    {
      title: "Staples",
      hostname: "https://www.staples.com",
    },
    {
      title: "Target",
      hostname: "https://www.target.com",
    },
    {
      title: "Walmart",
      hostname: "https://www.walmart.com",
    },
    {
      title: "Bestbuy",
      hostname: "https://www.bestbuy.com",
    },
  ]
  return (
    <React.Fragment>
      <section id="install">
        <Container>
          <Row className="pt-2 p-2">
            <Card className="border p-2 ">
              <div
                className="ribbon ribbon-top-left "
                hidden={props.hideRibbon}
              >
                <span>{props.message || "Get Started"}</span>
              </div>

              <CardBody>
                <center>
                  <h1
                    className="text-center mb-1 pt-5 fw-bold "
                    style={{ fontSize: "1.2rem" }}
                    hidden={props.hideMessage}
                  >
                    {props.content ||
                      "Install our free browser plugin and start sharing now"}
                  </h1>
                  <div className="text-center d-inline-flex gap-3 pb-5 pt-5">
                    <a
                      href="https://chrome.google.com/webstore/detail/shopping-cart-share/agppkkbcgmknmnkedglndagmnncdilck?hl=en&authuser=0"
                      target="_blank"
                      rel="noreferrer"

                      onClick={() => {
                        gtag('event', 'install_extension', {
                          extension: "chrome",
                        })
                      }}
                    >
                      <img
                        src={chrome}
                        alt=""
                        style={{ maxWidth: 50 }}
                        className="img-fluid mx-auto d-block "
                      />
                    </a>

                    <a
                      href="https://addons.mozilla.org/en-US/firefox/addon/shopping-cart-share"
                      target="_blank"
                      rel="noreferrer"

                      onClick={() => {
                        gtag('event', 'install_extension', {
                          extension: "firefox",
                        })
                      }}
                    >
                      <img
                        src={firefox}
                        alt=""
                        style={{ maxWidth: 50}}
                        className="img-fluid mx-auto d-block "
                      />
                    </a>

                    <img
                      src={edge}
                      alt=""
                      style={{ maxWidth: 50, opacity: 0.2 }}
                      className="img-fluid mx-auto d-block"
                    />

                    <img
                      src={safari}
                      alt=""
                      style={{ maxWidth: 50, opacity: 0.2 }}
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </center>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Install
