import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Images
import footerVector from "../../../assets/images/footerVector.png"
import pinExtension from "../../../assets/images/pin.png"
import createCart from "../../../assets/images/instructions/create-frame.png"

import shareCart1 from "../../../assets/images/instructions/share-cart1.png"
import shareCart2 from "../../../assets/images/instructions/share-cart2.png"
import shareCart3 from "../../../assets/images/instructions/share-cart3.png"

import useCart1 from "../../../assets/images/instructions/use-cart.png"
import useCart2 from "../../../assets/images/instructions/use-cart2.png"
import receiveInput from "../../../assets/images/instructions/receive-input.png"

//Import Components
import Accordian from "./accordian"

const ExtensionWelcomePage = () => {
  const [activeTab, setactiveTab] = useState("0")

  return (
    <React.Fragment>
      <section className="pt-5" style={{ backgroundColor: "blue" }} id="terms">
        <div className=" bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-white-50 p-1">
                <h1
                  className="text-white fw-bolder mb-3"
                  style={{ fontSize: 64 }}
                >
                  Welcome to Shopping Cart Share!
                </h1>
                <h4
                  className="text-white pt-3 p-1 fw-light"
                  style={{ lineHeight: 1.5, fontSize: "1rem" }}
                >
                 
                </h4>
              </div>
              <div className="row"></div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row className="pb-5">
                    <h1>
                    Below are instructions on how to use our browser extension
                    to build a multi retailer cart and share it with anyone you
                    want.
                  </h1>
                </Row>
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({ active: activeTab === "0" })}
                        onClick={() => {
                          setactiveTab("0")
                        }}
                      >
                        <i className="fa fa-adjust nav-icon d-block mb-2" />
                        <p
                          className="font-weight-bold mb-0"
                          style={{ fontSize: 14 }}
                        >
                          Pin the extension
                        </p>
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <i className="fa fa-shopping-cart nav-icon d-block mb-2" />
                        <p
                          className="font-weight-bold mb-0"
                          style={{ fontSize: 14 }}
                        >
                          Create a cart
                        </p>
                      </NavLink>

                      <NavLink
                        style={{ marginTop: 20 }}
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <i className="fa fa-share-alt d-block nav-icon mb-2" />
                        <p
                          className="font-weight-bold mb-0"
                          style={{ fontSize: 14 }}
                        >
                          Share a cart
                        </p>
                      </NavLink>

                      <NavLink
                        style={{ marginTop: 20 }}
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          setactiveTab("3")
                        }}
                      >
                        <i className="fa fa-arrow-down d-block nav-icon mb-2" />
                        <p
                          className="font-weight-bold mb-0"
                          style={{ fontSize: 14 }}
                        >
                          Receive carts
                        </p>
                      </NavLink>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card style={{ minHeight: 500 }}>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="0" id="buy">
                            <h1
                              className="card-title mb-4"
                              style={{ fontSize: 32 }}
                            >
                              Pin the extension
                            </h1>
                            <p style={{ fontSize: 14 }}>
                              Given that some browsers may tuck away the addon
                              icon within the extension menu, it's crucial to
                              ascertain that you've anchored the addon. Here are
                              some straightforward steps to do so:
                            </p>
                            <ol style={{ fontSize: 14 }}>
                              <li>
                                Click on the extensions icon available on your
                                browser.
                              </li>
                              <li>Search for the Shopping Cart Share logo</li>
                              <li>
                                {" "}
                                Hit the "Pushpin" icon to affix the extension to
                                your toolbar.
                              </li>
                            </ol>

                            <center>
                              <img
                                src={pinExtension}
                                className="pt-5"
                                style={{ maxWidth: 500 }}
                              ></img>
                            </center>
                          </TabPane>

                          <TabPane tabId="1" id="buy">
                            <h1
                              className="card-title mb-4"
                              style={{ fontSize: 32 }}
                            >
                              Create a Cart
                            </h1>

                            <h2 className="pt-5">
                              1) Shop as usual on a{" "}
                              <a href="/supported">Supported Retailers</a>{" "}
                              website.
                            </h2>
                            <h2 className="pt-2">
                              2) Once your carts are filled, simply open our
                              'Shopping Share Cart' extension and click 'Capture
                              Cart'.
                            </h2>
                            <h2 className="pt-2">
                              3) When ready to share your cart, click the
                              'Share' button.
                            </h2>

                            <img
                              src={createCart}
                              className="img-fluid mx-auto d-block rounded-3 pt-5"
                            ></img>
                          </TabPane>

                          <TabPane tabId="2">
                            <h4
                              className="card-title mb-4"
                              style={{ fontSize: 32 }}
                            >
                              Sharing Carts
                            </h4>
                            <h2 className="pt-5">
                              1) Click the 'Share' button on the extension.
                            </h2>
                            <img
                              src={shareCart1}
                              className="img-fluid mx-auto d-block rounded-3 pt-5"
                            ></img>

                            <h2 className="pt-5">
                              2) Specify the sharing settings
                            </h2>
                            <ul style={{ fontSize: 14 }}>
                              <li>
                                Privacy: Choose between a public or private
                                cart. Public carts are visible to everyone,
                                while private carts are only visible to those
                                with the link.
                              </li>
                              <li>
                                Title: Give your cart a title. This will be
                                visible to anyone who views your cart.
                              </li>
                              <li>
                                Description: Give your cart a description. This
                                will be visible to anyone who views your cart.
                              </li>
                              <li>
                                Change Pin: set a pin to protect your cart. This
                                will not be required to view the cart, but will
                                be required to edit and save the cart edits. if
                                left blank, no pin will be required and cart
                                can't be changed.
                              </li>
                              <li>
                                Recipient: Enter the email address of the person
                                you want to share the cart with (optional). We
                                will notify them via email that you have shared
                                a cart with them and provid the cart link.
                              </li>
                            </ul>
                            <h2 className="pt-5">
                              3) Click 'Create Shared Cart' to share your cart
                              and generate a link.
                            </h2>

                            <img
                              src={shareCart2}
                              className="img-fluid mx-auto d-block rounded-3 pt-5"
                            ></img>
                            <h2 className="pt-5">
                              3)Share the cart link or key with anyone you want
                              to share the cart with. You can click on the cart
                              key icon to copy the link to your clipboard or use
                              any of the supported sharing method such as
                              twitter, whatsapp, facebook, ..etc.
                            </h2>

                            <img
                              src={shareCart3}
                              className="img-fluid mx-auto d-block rounded-3 pt-5"
                            ></img>
                          </TabPane>

                          <TabPane tabId="3">
                            <h4
                              className="card-title mb-4"
                              style={{ fontSize: 32 }}
                            >
                              Receive Carts
                            </h4>

                            <h1 style={{ fontSize: 24 }} className="p-1 pt-5">
                              Option A) Using a cart key
                            </h1>
                            <ol style={{ fontSize: 14 }} className="m-1">
                              <li>
                                Click `recive` button and enter the cart key in
                                the input box then click `receive cart`
                                <center>
                                  <img
                                    src={receiveInput}
                                    className="pt-5"
                                    style={{ maxWidth: 500 }}
                                  ></img>
                                </center>
                              </li>
                              <li>
                                Review the cart and click `use cart` to populate
                                your retailer account with received items
                              </li>
                              <center>
                                <img
                                  src={useCart1}
                                  className="pt-5"
                                  style={{ maxWidth: 500 }}
                                ></img>
                              </center>
                            </ol>

                            <h1 style={{ fontSize: 24 }} className="p-1 pt-5">
                              Option B) Using a cart link
                            </h1>
                            <ol style={{ fontSize: 14 }}>
                              <li>
                                Visit the cart link, review and click use cart
                                <center>
                                  <img
                                    src={useCart1}
                                    className="pt-5"
                                    style={{ maxWidth: 500 }}
                                  ></img>
                                </center>
                              </li>
                              <li>
                                Click send to each reatailer to populate your
                                retailer account with received items{" "}
                              </li>
                              <center>
                                <img
                                  src={useCart2}
                                  className="pt-5"
                                  style={{ maxWidth: 500 }}
                                ></img>
                              </center>
                            </ol>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="pt-5">
                  <Col lg="12" className="pt-5">
                    <Card>
                      <CardBody>
                        <h1
                          className="card-title mb-4"
                          style={{ fontSize: 32 }}
                        >
                          Affiliate Program Disclosure
                        </h1>
                        <p style={{ fontSize: 14 }}>
                          Shopping cart share serves as a platform that allows
                          users to build multi retailer carts and share its
                          content with others. We are part of numerous
                          companies' affiliate programs. This signifies that if
                          a user completes a purchase through an affiliate link
                          provided by Shopping Cart Share, the company owning
                          the link remunerates Shopping Cart share with a
                          commission.
                        </p>

                        <p style={{ fontSize: 14 }}>
                          When a user "Receive a Cart", or clicks on a link on
                          the Shopping Cart Share website, the extension
                          triggers the affiliate code linked to that specific
                          action. This guarantees that, in the event of a user
                          finalizing a purchase via the affiliate link, Shopping
                          Cart Share is awarded a commission for aiding the
                          sale.
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <img src={footerVector} className="pt-5" />
          </Row>
          <Row></Row>
        </Container>
      </section>
      <center>
        <Row className="p-1 pt-0">
          <div className="mb-4">
            <h5 style={{ fontSize: "1.2rem" }}>Shopping Cart Share</h5>
          </div>

          <p className="mb-2" style={{ fontSize: "0.8rem" }}>
            {new Date().getFullYear()} © Copyright Shopping Cart Share. All
            Rights Reserved
          </p>
          <p style={{ fontSize: "0.8rem" }}>
            Shopping Cart Share may earn an affiliate commission from any
            purchases facilitated through the use of this website. Please see
            our <a href="/terms">terms of service</a> for more details.
          </p>
        </Row>
      </center>
    </React.Fragment>
  )
}

export default ExtensionWelcomePage
