import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap"

//Images
import chrome from "../../../assets/images/browsers/chrome_logo.svg"
import firefox from "../../../assets/images/browsers/firefox_logo.svg"
import edge from "../../../assets/images/browsers/edge_logo.svg"
import safari from "../../../assets/images/browsers/safari_logo.svg"

const InstallRetailer = props => {
  // get the retailer logo
  const getRetailerLogo = logo => {
    return require(`../../../assets/images/retailers/${logo}`)
  }

  return (
    <React.Fragment>
      <section id="install">
        <Container>
          <Row className="pt-2 p-2">
            <Card className="border p-2 ">
              <CardHeader className="bg-white">
                <img
                  className="img-fluid mx-auto d-block "
                  src={getRetailerLogo(props.retailer.logo)}
                />
              </CardHeader>
              <CardBody>
                <center>
                  <h1
                    className="text-center mb-1 pt-1 fw-bold "
                    style={{ fontSize: "1.0rem" }}
                  >
                    Install for {props.retailer.title}
                  </h1>
                  <div className="text-center d-inline-flex gap-3  pt-3">
                    <a
                      href="https://chrome.google.com/webstore/detail/agppkkbcgmknmnkedglndagmnncdilck/preview?hl=en&authuser=0"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        gtag("event", "install_extension", {
                          extension: "chrome",
                        })
                      }}
                    >
                      <img
                        src={chrome}
                        alt=""
                        style={{ maxWidth: 40 }}
                        className="img-fluid mx-auto d-block "
                      />
                    </a>

                    <a
                      href="https://addons.mozilla.org/en-US/firefox/addon/shopping-cart-share/"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        gtag("event", "install_extension", {
                          extension: "firefox",
                        })
                      }}
                    >
                      <img
                        src={firefox}
                        alt=""
                        style={{ maxWidth: 40, opacity: 0.2 }}
                        className="img-fluid mx-auto d-block"
                      />
                    </a>

                    <img
                      src={edge}
                      alt=""
                      style={{ maxWidth: 40, opacity: 0.2 }}
                      className="img-fluid mx-auto d-block"
                    />

                    <img
                      src={safari}
                      alt=""
                      style={{ maxWidth: 40, opacity: 0.2 }}
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </center>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default InstallRetailer
