import React from "react"
import "../../../../node_modules/swiper/swiper.scss"

import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import createCartFeature from "../../../assets/images/features/create-frame.png"
import shareCartFeature from "../../../assets/images/features/share-cart.png"

import client1 from "../../../assets/images/clients/1.png"
import client2 from "../../../assets/images/clients/2.png"
import client3 from "../../../assets/images/clients/3.png"
import client4 from "../../../assets/images/clients/4.png"
import client5 from "../../../assets/images/clients/5.png"

import chrome from "../../../assets/images/browsers/chrome_logo.svg"
import firefox from "../../../assets/images/browsers/firefox_logo.svg"
import edge from "../../../assets/images/browsers/edge_logo.svg"
import safari from "../../../assets/images/browsers/safari_logo.svg"
import heroShareLottie from "../../../assets/images/hero-share.json"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper"

import Lottie from "lottie-react"
import Install from "../install/install"

const UpdatePage = props => {
  document.title = "Update the extension"

  return (
    <React.Fragment>
      <Navbar />
      <section className="pt-5" style={{ backgroundColor: "blue" }} id="terms">
        <div className=" bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="text-white-50 p-1">
                <h1
                  className="text-white fw-bolder mb-3"
                  style={{ fontSize: "2.2rem" }}
                >
                  Welcome to the Future of Shopping with Shopping Cart Share
                  2.0!
                </h1>
                <h4
                  className="text-white   mb-3 pt-5"
                  style={{ fontSize: "1.0rem" }}
                >
                  We see you're still using our original Shopping Cart Share
                  extension. While it served us well in the past, we are excited
                  to introduce you to Shopping Cart Share 2.0. Our team has been
                  hard at work improving, streamlining, and enhancing your
                  shopping experience.
                  <br></br>
                  <br></br>
                  Transitioning to Shopping Cart Share 2.0 is as simple as a
                  click. Click on the button below to download the latest
                  version of the extension from the Browser Web Store. The
                  future of efficient and hassle-free online shopping awaits
                  you.
                </h4>
               
              </div>

              <Card className="border p-2 mt-5 pt-5">
                <div
                  className="ribbon ribbon-top-left"
                
                >
                  <span>Update Now</span>
                </div>

                <CardBody>
                  <center>
                    
                    <div className="text-center d-inline-flex gap-3 mt-1 mb-1">
                    <a
                      href="https://chrome.google.com/webstore/detail/shopping-cart-share/agppkkbcgmknmnkedglndagmnncdilck?hl=en&authuser=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={chrome}
                        alt=""
                        style={{ maxWidth: 50 }}
                        className="img-fluid mx-auto d-block "
                      />
                    </a>

                    <a
                      href="https://addons.mozilla.org/en-US/firefox/addon/shopping-cart-share"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={firefox}
                        alt=""
                        style={{ maxWidth: 50}}
                        className="img-fluid mx-auto d-block "
                      />
                    </a>

                      <img
                        src={edge}
                        alt=""
                        style={{ maxWidth: 50, opacity: 0.2 }}
                        className="img-fluid mx-auto d-block"
                      />

                      <img
                        src={safari}
                        alt=""
                        style={{ maxWidth: 50, opacity: 0.2 }}
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </center>
                </CardBody>
              </Card>
            </Col>
            <Col lg={5} md={5} sm={12} className="ms-lg-auto">
              <Lottie animationData={heroShareLottie} loop={false} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-1 pb-5 m-1">
        <Container>
          <Row className="align-items-center pt-0">
            <Col xs="12" lg="6">
              <Card
                className="p-3 shadow-lg rounded-3 mt-3 m-1 mb-5"
                style={{ minHeight: 350 }}
              >
                <ul style={{ fontSize: "0.9rem" }}>
                  <li className="pt-1">
                    <h1>Craft Your Multi-Retailer Cart</h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      Shop as usual on your favorite retailers' websites. Once
                      your carts are filled, simply open our 'Shopping Share
                      Cart' extension and select 'Capture Cart'. Watch in
                      real-time as the extension skillfully compiles your items
                      into a multi-retailer shareable cart. Check out the
                      ever-growing list of{" "}
                      <a href="/supported">Supported Retailers</a>
                    </h4>
                    <div className=" d-flex d-inline-flex gap-2 w-100 pb-5 p-2">
                      <Swiper
                        slidesPerView={1}
                        navigation
                        pagination={{
                          clickable: true,
                        }}
                        breakpoints={{
                          678: {
                            slidesPerView: 2,
                          },
                          992: {
                            slidesPerView: 3,
                          },
                          1400: {
                            slidesPerView: 4,
                          },
                        }}
                        autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Pagination, Navigation, Autoplay]}
                        className="owl-carousel clients-carousel bg-white rounded-4"
                        id="clients-carousel"
                        dir="ltr"
                      >
                        <SwiperSlide className="item ">
                          <div className="client-images">
                            <img
                              src={client1}
                              alt="client-img"
                              className="mx-auto img-fluid d-block"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                          <div className="client-images">
                            <img
                              src={client2}
                              alt="client-img"
                              className="mx-auto img-fluid d-block"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                          <div className="client-images">
                            <img
                              src={client3}
                              alt="client-img"
                              className="mx-auto img-fluid d-block"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                          <div className="client-images">
                            <img
                              src={client4}
                              alt="client-img"
                              className="mx-auto img-fluid d-block"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                          <div className="client-images">
                            <img
                              src={client5}
                              alt="client-img"
                              className="mx-auto img-fluid d-block"
                            />
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </li>
                  <li className="pt-1">
                    <h1>
                      Effortlessly share your favorite retailers shoppingcarts
                    </h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      Are you ready to share your finds? Save your shareable
                      multi-retailer cart for future reference or immediately
                      spread the joy by clicking 'Share'. This generates a
                      unique link that you can send to anyone, enabling them to
                      appreciate your handpicked selection.
                    </h4>
                  </li>
                  <li className="pt-1">
                    <h1>Create printable reprots</h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      Effortlessly create a printable report of multi retailer
                      cart content.
                    </h4>
                  </li>
                  <li className="pt-1">
                    <h1>No registratio and completely free</h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      No registration is required and our extension is
                      completely free.
                    </h4>
                  </li>
                </ul>
              </Card>

              <img
                src={createCartFeature}
                className="img-fluid mx-auto d-block rounded-3  shadow-lg"
              ></img>
            </Col>
            <Col xs="12" lg="6">
              <Card
                className="p-3 shadow-lg rounded-3 mt-3 m-1 mb-5"
                style={{ minHeight: 428 }}
              >
                <ul style={{ fontSize: "0.9rem" }}>
                  <li>
                    <h1> Upgraded User Interface</h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      We've made our new extension more intuitive and
                      user-friendly, ensuring you have the best possible online
                      shopping experience.
                    </h4>
                  </li>
                  <li>
                    <h1> Enhanced Performance:</h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      Our new extension is faster, more reliable, and robust
                      than ever before, ensuring seamless interaction with all
                      your favorite retailers.
                    </h4>
                  </li>
                  <li>
                    <h1> Expanded Retailer Support</h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      We have significantly broadened our list of supported
                      retailers, providing you with a vast shopping universe to
                      explore and curate from.
                    </h4>
                  </li>
                  <li>
                    <h1> Improved Sharing Options</h1>
                    <h4
                      className="fw-lighter"
                      style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                    >
                      With new and innovative sharing options, Shopping Cart
                      Share 2.0 takes the ease and convenience of sharing your
                      carts to a whole new level.
                    </h4>
                  </li>
                </ul>
              </Card>

              <img
                src={shareCartFeature}
                className="img-fluid mx-auto d-block rounded-3  shadow-lg"
              ></img>
              <div className="mt-4"></div>
            </Col>
          </Row>
          <Row className="align-items-center pt-5 p-3">
          <Card className="border p-2 mt-5 pt-5">
                <div
                  className="ribbon ribbon-top-left "
                  hidden={props.hideRibbon}
                >
                  <span>Update Now</span>
                </div>

                <CardBody className="pt-5 pb-5">
                  <center>
                    
                    <div className="text-center d-inline-flex gap-3 mt-1 mb-1">
                    <a
                      href="https://chrome.google.com/webstore/detail/shopping-cart-share/agppkkbcgmknmnkedglndagmnncdilck?hl=en&authuser=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={chrome}
                        alt=""
                        style={{ maxWidth: 50 }}
                        className="img-fluid mx-auto d-block "
                      />
                    </a>

                    <a
                      href="https://addons.mozilla.org/en-US/firefox/addon/shopping-cart-share"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={firefox}
                        alt=""
                        style={{ maxWidth: 50}}
                        className="img-fluid mx-auto d-block "
                      />
                    </a>
                      <img
                        src={safari}
                        alt=""
                        style={{ maxWidth: 50, opacity: 0.2 }}
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </center>
                </CardBody>
              </Card>
          </Row>
        </Container>
      </section>

      <Footer />
    </React.Fragment>
  )
}

export default UpdatePage
