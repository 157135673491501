import React from "react"
import { Container, Row } from "reactstrap"

//Import Components
import CardBox from "./card-box"
import cartPng from "../../../../assets/images/cart.png"
import productPng from "../../../../assets/images/productPng.jpg"
import retailerPng from "../../../../assets/images/retailer.png"

const CardsMini = () => {
  const stats = [
    {
      icon: cartPng,
      title: "Create a multi store cart",
      value: "",
      link: "/#howCreate",
    },

    {
      icon: productPng,
      title: "Share the cart with a click.",
      value: "",
      link: "/#howShare",
    },
    {
      icon: retailerPng,
      title:
        "Others import the cart.",
      value: "",
      link: "/#howImport",
    },
  ]

  return (
    <React.Fragment>
      <section className="section bg-white p-0" style={{position:"relative", top:"-50px"}}>
        <Container>
          <div className="p-0">
            <Row>
              <CardBox stats={stats} />
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CardsMini
