import React from "react"
import { Container } from "reactstrap"

const SearchBox = () => {
  const [searchKey, setSearchKey] = React.useState("")

  return (
    <React.Fragment>
      <Container>
        <div className="ibox ">
          <div className="ibox-content  shadow-lg rounded-4 ">
            <div className="row">
              <div className="col-12 text-center pb-5">
                <div
                  className="input-group input-group-lg"
                  style={{ borderRadius: 7, minWidth: 350 }}
                >
                  <input
                    type="text"
                    value={searchKey}
                    onChange={e => {
                      e.preventDefault()
                      setSearchKey(e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        if (searchKey === "") return
                        window.location.href = `/cart/receive/${searchKey}`
                      }
                    }}
                    className="form-control bg-light text-dark border-light mt-5"
                    style={{
                      minHeight: 50,
                      fontSize: 14,
                    }}
                    placeholder="Looking for a shared cart? Enter the cart ID here. "
                  />
                  <div className="input-group-append  mt-5">
                    <button
                      style={{
                        minHeight: 50,
                        fontSize: 14,
                        padding: "0px 20px",
                      }}
                      className="btn btn-lg btn-outline-secondary"
                      type="button"
                      onClick={() => {
                        if (searchKey !== "") {
                          window.location.href = `/cart/receive/${searchKey}`
                        }
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
       
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default SearchBox
