import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

const RetailerBox = props => {
  // get the retailer logo
  const getRetailerLogo = logo => {
    return require(`../../../assets/images/retailers/${logo}`)
  }

  return (
    <React.Fragment>
      <Container>
        <Row>
          <h1>Supported Retailers</h1>
        </Row>
        <Row>
          <Col
            className="d-inline d-block d-flex p-3"
            style={{ minHeight: 250 }}
          >
            <div className="d-flex flex-wrap justify-content-center gap-4 rounded-3 pt-5  w-100 bg-white shadow-lg mb-1">
              {props.retailers.map((retailer, key) => {
                return (
                  <Card
                    key={key}
                    className="rounded-3 shadow-lg d-block"
                    style={{
                      width: 150,
                      height: 120,
                    }}
                  >
                    <CardBody className="text-center">
                      <a
                        href={retailer.hostname}
                      >
                        <img
                          src={getRetailerLogo(retailer.logo)}
                          style={{ width: 50 }}
                        />
                        <br></br>
                        
                        <h5 className="pt-2">{retailer.title}</h5>
                        <a href={retailer.origin} target="_blank" rel="noreferrer" ><i className="fa fa-external-link-alt" ></i></a>
                      </a>
                    </CardBody>
                  </Card>
                )
              })}
            </div>
          </Col>
          
           
        </Row>
        <Row style={{paddingTop:10}}>
          <h1>Retailers comming soon ...</h1>
        </Row>
        <Row>
          <Col
            className="d-inline d-block d-flex p-3"
            style={{ minHeight: 250 }}
          >
            <div className="d-flex flex-wrap justify-content-center gap-4 rounded-3 pt-5  w-100 bg-white shadow-lg mb-1">
              {props.next.map((retailer, key) => {
                return (
                  <Card
                    key={key}
                    className="rounded-3 shadow-lg d-block"
                    style={{
                      width: 150,
                      height: 120,
                      backgroundColor: "#f2f6fa",
                    }}
                  >
                    <CardBody className="text-center" >
                      <a
                        href={retailer.hostname}
                      
                      >
                        <img
                          src={getRetailerLogo(retailer.logo)}
                          style={{ width: 50 }}
                        />
                        <br></br>
                        <h5 className="pt-2">{retailer.title}</h5>
                        <a href={retailer.origin} target="_blank" rel="noreferrer" ><i className="fa fa-external-link-alt" ></i></a>
                      </a>
                    </CardBody>
                  </Card>
                )
              })}
            </div>
          </Col>
          
          <Col xs={12}>
            <a href="/retailers/recommend"  className="btn btn-lg w-100 btn-outline-primary m- ">
              Recommend a retailer
            </a>
          </Col>
        </Row>
        
      </Container>
    </React.Fragment>
  )
}

export default RetailerBox
