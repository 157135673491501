import React, { useState } from "react"
import { Col, Container, Nav, Row } from "reactstrap"
import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"
import { Helmet } from "react-helmet"

import Lottie from "lottie-react"

import storeLottie from "../../../assets/images/store.json"
const RetailersRecommend = props => {
  document.title = "Recommended Retailers - Shopping Cart Share"

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="theme-color" content="#4A4A4A" />
        <meta
          name="description"
          content="Support for all your favorite retailers from Amazon to Walmart and more. Build your cart from multiple retailers and share it with anyone."
        />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Shopping Cart Share, browser extension, Share Cart, Multi-Retailer Cart, Transfer Cart, Share Amazon Cart, Share Walmart Cart, Social Shopping List, Share Amazon Wishlist, Combine Store Carts"
        />
        <meta
          property="og:title"
          content="Recommended Retailers - Shopping Cart Share"
        />
        <meta
          property="og:url"
          content="https://shoppingcartshare.com/retailers/recommend"
        />
        <meta
          property="og:image"
          content="https://shoppingcartshare.com/supported.png"
        />
        <meta
          property="og:description"
          content="Support for all your favorite retailers from Amazon to Walmart and more. Build your cart from multiple retailers and share it with anyone."
        />
        <meta property="og:site_name" content="Shopping Cart Share" />
        <meta property="og:locale" content="en_US" />
      </Helmet>

      <Navbar />
   
      <section className="pt-5">
        <Container fluid>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfZB-AkOnpyiwf5dvibiTeAURRmVcA0SDu1YZ7WOTjSeko8Kw/viewform?embedded=true"
            style={{
              minWidth: "100%",
              minHeight: 950,
              padding: 0,
              margin: 0,
              border: 0,
            }}
          >
            Loading…
          </iframe>
        </Container>
      </section>

      <Footer />
    </React.Fragment>
  )
}

export default RetailersRecommend
