import React, { useEffect, useState } from "react"

//Import Components
import Navbar from "../../components/layout/public/menu/menu"
import Section from "../../components/layout/public/hero/section"
import CardsMini from "../../components/layout/public/hero/cards-mini"
import Footer from "../../components/layout/public/footer/footer"

import Install from "./install/install"
import Features from "./features/features"
import SearchBox from "./cart/search/search-box"
import { Helmet } from "react-helmet"

import websiteLD from "../../seo/website.json"
import RetailerBox from "./supported/retailer-box"
import { Container } from "reactstrap"
 

const Home = () => {
  document.title = "Shopping Cart Share"

  const retailers = [
    {
      title: "Amazon",
      hostname: "/retailer/amazon",
      logo: "amazon.png",
      origin: "https://www.amazon.com",
    },
    {
      title: "Staples",
      hostname: "/retailer/staples",
      logo: "staples.png",
      origin: "https://www.staples.com",
    },
    {
      title: "Target",
      hostname: "/retailer/target",
      logo: "target.png",
      origin: "https://www.target.com",
    },
    {
      title: "Walmart",
      hostname: "/retailer/walmart",
      logo: "walmart.png",
      origin: "https://www.walmart.com",
    },
    {
      title: "Bestbuy",
      hostname: "/retailer/bestbuy",
      logo: "bestbuy.png",
      origin: "https://www.bestbuy.com",
    },
  ]

  const retailers_comming_soon = [
    {
      title: "eBay",
      hostname: "/retailer/ebay",
      logo: "ebay.png",
      origin:"https://www.ebay.com",
    },
    {
      title: "AliExpress",
      hostname: "/retailer/aliexpress",
      logo: "aliexpress.png",
      origin:"https://www.aliexpress.com",
    },
    {
      title: "Apple Store",
      hostname: "/retailer/apple",
      logo: "apple.png",
      origin:"https://www.apple.com",
    },
    {
      title: "Costco",
      hostname: "/retailer/costco",
      logo: "costco.png",
      origin:"https://www.costco.com",
    },
    {
      title: "Macy's",
      hostname: "/retailer/macys",
      logo: "macys.png",
      origin:"https://www.macys.com",
    },
    {
      title: "Textbooks",
      hostname: "/retailer/textbooks",
      logo: "textbooks.png",
      origin:"https://www.textbooks.com",
    },
    {
      title: "Wayfair",
      hostname: "/retailer/wayfair",
      logo: "wayfair.png",
      origin:"https://www.wayfair.com",
    },
    {
      title: "Walgreens",
      hostname: "/retailer/walgreens",
      logo: "walgreens.png",
      origin:"https://www.walgreens.com",
    },

    {
      title: "Sam's Club",
      hostname: "/retailer/samsclub",
      logo: "samsclub.png",
      origin:"https://www.samsclub.com",
    },
    {
      title: "H & M",
      hostname: "/retailer/hm",
      logo: "hm.png",
      origin:"https://www2.hm.com",
    },
    {
      title: "Home Depot",
      hostname: "/retailer/homedepot",
      logo: "homedepot.png",
      origin:"https://www.homedepot.com",
    },
    ,
    {
      title: "Nike",
      hostname: "/retailer/nike",
      logo: "nike.png",
      origin:"https://www.nike.com",
    },

    {
      title: "Office Depot",
      hostname: "/retailer/officedepot",
      logo: "officedepot.png",
      origin:"https://www.officedepot.com",
    },
  ]

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="theme-color" content="#4A4A4A" />
        <meta
          name="description"
          content="Share your shopping cart easily, share your favorite retailer carts with friends, and combine multiple retailers such as amazon, bestbuy, walmart, target carts into one cart with Shopping Cart Share."
        />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Shopping Cart Share, Share Cart, Multi-Retailer Cart, Transfer Cart, Share Amazon Cart, Share Walmart Cart, Share Amazon Wishlist, Combine Store Carts"
        />
        <meta
          property="og:title"
          content="Shopping Cart Share - Share Your Shopping Cart Easily"
        />
        <meta property="og:url" content="https://shoppingcartshare.com" />
        <meta
          property="og:image"
          content="https://shoppingcartshare.com/site.png"
        />
        <meta
          property="og:description"
          content="Share your shopping cart easily, share your favorite retailer carts with friends, and combine multiple retailers such as amazon, bestbuy, walmart, target carts into one cart with Shopping Cart Share."
        />
        
        <meta property="og:site_name" content="Shopping Cart Share" />
        <meta property="og:locale" content="en_US" />

        
        <script type="application/ld+json">{JSON.stringify(websiteLD)}</script>
      </Helmet>

      <Navbar />
      <Section />
      <CardsMini />
      <SearchBox />
      <Features />
      <div className="pt-5"></div>
      <Install
        content="Ready to transform your shopping experience? Get Shopping cart share now"
        message="It's free"
      />

      <Container fluid className="pb-5">
        <RetailerBox retailers={retailers} next={retailers_comming_soon} />
        <div className="pt-2 pb-5"></div>
        <Install
          content="Ready to share your carts? Get Shopping cart share now"
          message="It's free"
        />
      </Container>

      <Footer />
    </React.Fragment>
  )
}

export default Home
