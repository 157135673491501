import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Nav,
  NavbarToggler,
  NavItem,
  Navbar,
  NavLink,
  Collapse,
  NavbarBrand,
  Input,
} from "reactstrap"

import logo from "../../../../assets/images/logo.png"

const Navbar_Page = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState("")

  const toggle = () => setIsOpen(!isOpen)

  return (
    <React.Fragment>
      <Navbar
        expand={"lg"}
        container="sm"
        style={{ backgroundColor: "#2a3042" }}
        className="sticky-top"
      >
        <NavbarBrand href="/" className="navbar-logo text-white">
          <a
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              paddingTop: "0.5rem",
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            Shopping Cart Share
          </a>
        </NavbarBrand>
        <NavbarToggler
          onClick={toggle}
          className="border-white text-white bg-white"
        />
        <Collapse isOpen={isOpen} navbar id="topnav-menu-content" className="gap-2">
          <Nav className="me-auto gap-1" navbar></Nav>
          
          
          <hr></hr>
          <div
           
            className="p-1    text-white"
            style={{ fontSize: "0.8rem" }}
          >
           <Input
            type="text"
            onChange={e => setSearch(e.target.value)}
            value={search}
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.preventDefault()
                if (search === "") return
                window.location.href = `/cart/receive/${search}`
              }
            }}
            className="mt-0 mb-0 p-1 text-white  "
            style={{
              borderRadius: 8,
              minWidth: 250,
              fontSize: 10,
              maxWidth: 500,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "gray",
            }}
            placeholder="Search for a cart"
          ></Input>
          </div>
         <NavLink
            href="/plugins"
            className="p-1   text-white"
            style={{ fontSize: "0.8rem" }}
          >
            Plugins
          </NavLink>

          <NavLink
            href="/supported"
            className="p-1  text-white"
            style={{ fontSize: "0.8rem" }}
          >
            Retailers
          </NavLink>
          <NavLink
            href="/terms"
            className="p-1   text-white"
            style={{ fontSize: "0.8rem" }}
          >
            Terms
          </NavLink>
          <NavLink
            href="/plugins"
            className="p-1   text-white"
            style={{ fontSize: "0.8rem" }}
          >
            About 
          </NavLink>
         
        </Collapse>
      </Navbar>
    </React.Fragment>
  )
}

export default Navbar_Page
