import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

//Import Components
import FooterLink from "./footer-link"

const Features = () => {
  const footerLinks = [
    {
      title: "Company",
      links: [
        { title: "About us", link: "/contact" },
        { title: "Contact us", link: "/contact" },
      ],
    },
    {
      title: "Resources",
      links: [
        { title: "Features", link: "/plugins" },
        { title: "Privacy Policy", link: "/privacy" },
        { title: "Browser Plugins", link: "/plugins" },
        { title: "Terms & Conditions", link: "/terms" },
        { title: "Supported retailers", link: "/supported" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <footer className="landing-footer pt-5 p-2">
        <Container>
          <Row>
            <Col lg="6" sm="6">
              <FooterLink />
            </Col>

            {footerLinks.map((footerLink, key) => (
              <Col lg="3" sm="6" key={key}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title" style={{ fontSize: "1.1rem" }}>{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        <Link to={Flink.link} style={{ fontSize: "0.8rem" }}>{Flink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Features
