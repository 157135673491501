import React from "react"
import { Navigate } from "react-router-dom"

// General public pages
import Home from "pages/public/public"
import Retailers from "pages/public/supported"
import TermsPage from "pages/public/legal/terms"
import UpdatePage from "pages/public/update/update"
import BrowserPluginsPage from "pages/public/plugins"
import ReceiveCart from "pages/public/cart/receive/receive"
import PrivacyPolicyPage from "pages/public/legal/privacy-policy"
import ExtensionWelcomePage from "pages/public/extension/welcome"
import RetailersRecommend from "pages/public/supported/recommend"
import UninstallPage from "pages/public/extension/uninstall"
import SearchPage from "pages/public/cart/search/search"
import RetailerPage from "pages/public/supported/retailer-page"
import CreateCart from "pages/public/cart/create/create"

const publicRoutes = [
  // { path: "/", component: <CommingSoon /> },
 
  { path: "/", component: <Home /> },

  { path: "/supported", component: <Retailers /> },

  { path: "/terms", component: <TermsPage></TermsPage> },
  { path: "/privacy", component: <PrivacyPolicyPage></PrivacyPolicyPage> },
  { path: "/plugins", component: <BrowserPluginsPage></BrowserPluginsPage> },
  { path: "/view", component: <UpdatePage></UpdatePage> },

  { path: "/retailers/recommend", component: <RetailersRecommend></RetailersRecommend> },
  { path: "/retailer/:retailer", component: <RetailerPage></RetailerPage> },

  // Cart related
  { path: "/cart/receive/:cartKey", component: <ReceiveCart /> },
  { path: "/cart/create", component: <CreateCart /> },
  
  { path: "/shared/cart/search", component: <SearchPage /> },


{ path: "/o/welcome", component: <ExtensionWelcomePage></ExtensionWelcomePage> },
{ path: "/o/uninstall", component: <UninstallPage></UninstallPage> },

  {
    path: "/*",
    exact: true,
    component: <Navigate to="/" />,
  },
]

export { publicRoutes }
