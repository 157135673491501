import React, { useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import createCartFeature from "../../../assets/images/features/create-frame.png"
import shareCartFeature from "../../../assets/images/features/share-cart.png"

import importSend from "../../../assets/images/features/import-send.png"

import multiStore from "../../../assets/images/multistore.png"

import sharingLottie from "../../../assets/images/sharing.json"
import importLottie from "../../../assets/images/import.json"

import Lottie from "lottie-react"
 

//swiper
const Features = (props) => {
  return (
    <React.Fragment>
      <section className="section pt-5 p-2 bg-white" id="how">
        <Container>
          <Row className="align-items-start" id="howCreate">
            <h1 style={{ fontSize: 32 }} className="fw-bolder pb-1">
              How it works
            </h1>
          </Row>
     
          <Row className="align-items-center pt-2">
            <Col lg="5">
              <h1>1) Craft Your {props.retailer || "Multi-Retailer"} Cart:</h1>
              <h4
                className="fw-lighter"
                style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
              >
                Shop as usual on {props.retailer || "your favorite retailers'"} websites. Once your
                carts are filled, simply open our 'Shopping Share Cart'
                extension and select 'Capture Cart'. Watch in real-time as the
                extension skillfully compiles your items into a multi-retailer
                shareable cart. Check out the ever-growing list of{" "}
                <a href="/supported" hidden={props.retailer?true:false}> Supported Retailers</a>
              </h4>
              <div className="d-flex flex-wrap gap-2">
                <div className="mt-4 me-md-0">
                  <img
                    src={multiStore}
                    className="img-fluid mx-auto d-block rounded-3"
                  ></img>
                </div>
              </div>
            </Col>

            <Col lg="6" className="ms-auto pt-1">
              <div className="mt-4 mt-lg-0">
                <Row>
                  <Col sm="12">
                    <Card className="border shadow-lg ">
                      <CardBody className="p-0 d-inline-flex justify-content-center">
                        <img
                          src={createCartFeature}
                          className="img-fluid mx-auto d-block rounded-3  shadow-lg"
                        ></img>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <hr className="my-5" />

          <Row className="align-items-center" id="howShare">
            <Col lg="6">
              <h1>2) Share the Joy of Shopping:</h1>
              <h4
                className="fw-lighter"
                style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
              >
                Are you ready to share your finds? Save your shareable
                multi-retailer cart for future reference or immediately spread
                the joy by clicking 'Share'. This generates a unique link that
                you can send to anyone, enabling them to appreciate your
                handpicked selection.
              </h4>
              <img
                src={shareCartFeature}
                className="img-fluid mx-auto d-block rounded-3 pt-1  shadow-lg"
              ></img>
            </Col>

            <Col sm="6" className="pt-1">
              <Lottie animationData={sharingLottie} loop={true} />
            </Col>
          </Row>
          <Row className="align-items-center pt-5" id="howImport">
            <Col sm="6">
              <Lottie animationData={importLottie} loop={true} />
            </Col>
            <Col lg="6" className="pt-1">
              <div>
                <h1>3) Import and Shop with Ease:</h1>
                <h4
                  className="fw-lighter"
                  style={{ lineHeight: 1.5, fontSize: "0.9rem" }}
                >
                  Received a shared cart link? Just visit the link, review the
                  items, and once content, click 'Send to retailer' to import
                  the chosen cart into your account. Shopping couldn't get any
                  easier!
                </h4>
              </div>
              <img
                src={importSend}
                className="img-fluid mx-auto d-block rounded-3  shadow-lg"
              ></img>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Features
