import React, { useEffect, useState } from "react"
import { Container, Nav } from "reactstrap"
import Navbar from "../../../components/layout/public/menu/menu"
import Footer from "../../../components/layout/public/footer/footer"
import SupportHeroSection from "./hero"
import Install from "../install/install"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import Features from "../features/features"

import retailerLD from "../../../seo/retailer.json"

const RetailerPage = props => {
  const { retailer } = useParams()

  const getRetailerLogo = logo => {
    // check retailer key
    if (!retailers_key[retailer]) {
      window.location.href = "/supported"
    }
    return require(`../../../assets/images/retailers/${logo}`)
  }

  const retailers_key = {
    amazon: "Amazon",
    staples: "Staples",
    target: "Target",
    walmart: "Walmart",
    bestbuy: "Bestbuy",
    ebay: "Ebay",
    aliexpress: "AliExpress",
    apple: "Apple Store",
    costco: "Costco",
    macys: "Macy's",
    textbooks: "Textbooks",
    wayfair: "Wayfair",
    walgreens: "Walgreens",
    samsclub: "Sam's Club",
    hm: "H & M",
    homedepot: "Home Depot",
    nike: "Nike",
    officedepot: "Office Depot",
  }

  const retailers_link = {
    amazon: "https://amazon.com",
    staples: "https://staples.com",
    target:  "https://target.com",
    walmart:  "https://walmart.com",
    bestbuy:  "https://bestbuy.com",
    ebay: "https://ebay.com",
    aliexpress:  "https://aliexpress.com",
    apple:  "https://apple.com",
    costco:   "https://costco.com",
    macys:  "https://macys.com",
    textbooks: "https://textbooks.com",
    wayfair: "https://wayfair.com",
    walgreens: "https://walgreens.com",
    samsclub:  "https://samsclub.com",
    hm: "https://hm.com",
    homedepot: "https://homedepot.com",
    nike: "https://nike.com",
    officedepot:  "https://officedepot.com",
  }

  document.title = `Share ${retailer} cart with anyone -  Shopping Cart Share`

  // generate structred data
  const getJSONLD = (json, retailer) => {
    if (!retailers_key[retailer]) {
      window.location.href = "/supported"
    }

    json.mainEntityOfPage.id = json.mainEntityOfPage.id.replace(
      "{retailer}",
      retailer
    )
    
    json.headline = json.headline.replace("{retailer}", retailers_key[retailer])
    json.title = json.headline.replace("{retailer}", retailers_key[retailer])
    json.description = json.description.replace(
      "{retailer}",
      retailers_key[retailer]
    )

    return json
  }

  useEffect(() => {
    // check if retailer is supported
    if (!retailers_key[retailer]) {
      window.location.href = "/supported"
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="theme-color" content="#4A4A4A" />
        <meta
          name="description"
          content={`Share your ${retailer} shopping cart easily, share your favorite retailer carts with friends, and combine carts from multiple retailers in one cart`}
        />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content={`share shopping cart, share ${retailer} cart`}
        />
        <meta
          property="og:title"
          content={`Share ${retailer} cart with anyone -  Shopping Cart Share`}
        />
        <meta
          property="og:url"
          content="https://shoppingcartshare.com/supported"
        />
        <meta
          property="og:image"
          content="https://shoppingcartshare.com/supported.png"
        />
        <meta
          property="og:description"
          content={`Share your ${retailer} shopping cart easily, share your favorite retailer carts with friends, and combine carts from multiple retailers in one cart`}
        />
        <meta property="og:site_name" content="Shopping Cart Share" />
        <meta property="og:locale" content="en_US" />

        <script type="application/ld+json">
          {JSON.stringify(getJSONLD(retailerLD, retailer))}
        </script>
      </Helmet>

      <Navbar />
      <SupportHeroSection
        logo={getRetailerLogo(retailer + ".png")}
        message={`share ${retailer} cart`.toUpperCase()}
        origin={retailers_link[retailer]}
        hideRecommend={true}
        submessage={`Looking to share your ${retailer} shopping cart with others? Unleash the power of Shopping Cart Share extension! Seamlessly share your favourite products, and make collaborative shopping decisions. Dive into a unique shopping experience with ${retailer} and Shopping Cart Share today!`}
      />
      <div className="page-content">
        <Container fluid>
          <Features retailer={retailers_key[retailer]}></Features>
          <Install />
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default RetailerPage
