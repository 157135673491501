import React from "react"
import { Container, Row, Col } from "reactstrap"

import Lottie from "lottie-react"

import storeLottie from "../../../assets/images/store.json"
const SupportHeroSection = (props) => {
  return (
    <React.Fragment>
      <section
        className="pt-5"
        style={{ backgroundColor: "blue" }}
        id="supported"
      >
        <div className=" bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="text-white-50 p-1">
              
              
                <h1
                  className="text-white fw-bolder mb-3"
                  style={{ fontSize: "2.1rem" }}
                >
                  <a href={props.origin} className="text-white" target="_blank"  rel="noreferrer">
              <img src={props.logo} className="m-2 pb-0 pt-0  img-fluid img-thumbnail " style={{ maxWidth: 100 }}  hidden={props.logo?false:true}/>   
                  {props.message || "Unify and Share your cart from our growing list of supported retailers."}
                  </a>
                </h1>
                <h4
                  className="text-white pt-3 p-1 fw-light"
                  style={{ lineHeight: 1.5, fontSize: "1rem" }}
                >
                  
                  {props.submessage || "Consistent with its promise, Shopping cart share continually broadens its scope by integrating with a diverse and ever-increasing roster of online retailers. Experience the convenience of unifying, and sharing your cart. We're constantly working to expand our list of supported retailers to make your shopping experience even better. If you'd like to see a specific retailer added, we'd love to hear from you."}
                </h4>
              </div>
              <div className=" d-flex d-inline-flex gap-2 w-100 pb-5 p-2">
                <a href="/retailers/recommend" className="btn btn-lg w-100 btn-primary " hidden={props.hideRecommend || false}>
                  Recommend a retailer
                </a>
              </div>
            </Col>
            <Col lg={6}>
            
            <Lottie animationData={storeLottie} loop={true} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default SupportHeroSection
