import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
 
const rootReducer = combineReducers({
  Layout
});

export default rootReducer;
